import { useState, useEffect } from "react";
import axios from 'axios'; // Import Axios
import { API_URL } from "../constants";
import { useHistory } from "react-router-dom";

export default function TypoSelect({
  props,
  motor,
  setMotor,
  token
}) {

  const [maintenanceFreqIntervention, setMaintenanceFreqIntervention] =
  useState([]);
  const [typographies, setTypographies] = useState([]);
  const [selectedTypo, setSelectedTypo] = useState("");
  const [typoSelected, setTypoSelected] = useState(false);
  const navigate = useHistory();

  const showMessagePopup = (message) => {
    alert(message);
  };

  const handleTypoChange = (event) => {
    setSelectedTypo(event.target.value);
    setTypoSelected(event.target.value !== "");
  };

  useEffect(() => {
    getTypo();
  }, [props.selectedMotor]);

  async function getTypo() {
    try {
      const response = await axios.get(`${API_URL}/typo/typoslist`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      if (response.status === 401) {
        showMessagePopup("ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer.");
        navigate.push("/login");
        return;
      }

      const json = response.data;
      setTypographies(json.data);
    } catch (error) {
      console.error(error.message);
      return [];
    }
  }

  const handleSaveTypo = async () => {
    try {
      const response = await axios.put(`${API_URL}/typo/typoselect`, {
        id_moteur: motor._id,
        nomTypo: selectedTypo,
      }, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      if (response.status === 401) {
        showMessagePopup("ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer.");
        navigate.push("/login");
        return;
      }

      const json = response.data;
      setMotor(json);
      localStorage.setItem("motor", JSON.stringify(json));
      let tabFreqIntervention = [...maintenanceFreqIntervention];
      motor.maintenance.forEach((maintenance) => {
        tabFreqIntervention.push(maintenance.freqIntervention);
      });
      setMaintenanceFreqIntervention(tabFreqIntervention);
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <>
      <select
        className="appearance-none bg-white border-2 border-red-500 focus:border-loginBordure rounded px-4 py-2 text-sm w-40 sm:w-80 font-bold text-gray-700 mt-5 w-5/6 lg:w-2/3 "
        value={selectedTypo}
        onChange={handleTypoChange}
      >
        <option value="">Veuillez choisir votre typo...</option>
        {typographies.map((typo) => (
          <option key={typo.id} value={typo.id}>
            {typo.nomTypo}
          </option>
        ))}
      </select>

      {typoSelected && (
        <button
          className="bg-orangeButton hover:bg-orange-600 text-white text-base border-none rounded-full px-4 py-1 w-40 ml-4"
          onClick={handleSaveTypo}
        >
          Valider
        </button>
      )}
    </>
  );
}
