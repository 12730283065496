import React, { useState } from "react";
import Modal from "../Etalonnage/modal";
import useModal from "../Etalonnage/useModal";
import axios from "axios";
import { API_URL } from "../constants";

const EtalonnageMoteur = ({ motor, token, setMotor }) => {
  const { isShowing, toggle } = useModal();
  const [calibrationData, setCalibrationData] = useState({
    current: { sensor: 0, measured: 0 },
    conso: { sensor: 0, measured: 0 },
    temperature: { sensor: 0, measured: 0 },
    rmsAX: { sensor: 0, measured: 0 },
    rmsAY: { sensor: 0, measured: 0 },
    rmsAZ: { sensor: 0, measured: 0 },
    rmsBX: { sensor: 0, measured: 0 },
    rmsBY: { sensor: 0, measured: 0 },
    rmsBZ: { sensor: 0, measured: 0 }
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    const [type, field] = name.split("-");
    const parsedValue = parseFloat(value);
    setCalibrationData({
      ...calibrationData,
      [type]: {
        ...calibrationData[type],
        [field]: isNaN(parsedValue) ? "" : parsedValue
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    const adjustedData = { id_moteur: motor._id };
    const fields = {
      current: "etalonnageCourant",
      conso: "etalonnageConso",
      temperature: "etalonnageTemperature",
      rmsAX: "etalonnageVitesseX1RMSA",
      rmsAY: "etalonnageVitesseY1RMSA",
      rmsAZ: "etalonnageVitesseZ1RMSA",
      rmsBX: "etalonnageVitesseX2RMSB",
      rmsBY: "etalonnageVitesseY2RMSB",
      rmsBZ: "etalonnageVitesseZ2RMSB"
    };

    for (let key in calibrationData) {
      const sensorValue = calibrationData[key].sensor;
      const measuredValue = calibrationData[key].measured;

      if (sensorValue !== 0 || measuredValue !== 0) {
        const adjustment = parseFloat((measuredValue - sensorValue));
        adjustedData[fields[key]] = adjustment;
      }
    }

    try {
      const response = await axios.post(`${API_URL}/moteur/etalonnage`, adjustedData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      });

      if (response.status === 200) {
        const updatedMotor = response.data;
        setMotor(updatedMotor);
        setSuccessMessage("Les données d'étalonnage ont été envoyées avec succès.");
        setErrorMessage("");
        console.log("Calibration data sent successfully:", updatedMotor);
        // Auto-hide the success message after 3 seconds and close the modal
        setTimeout(() => {
          setSuccessMessage("");
          toggle();
        }, 3000);
      } else {
        setSuccessMessage("");
        setErrorMessage("Échec de l'envoi des données d'étalonnage.");
        console.error("Failed to send calibration data:", response.status);
        // Auto-hide the error message after 5 seconds
        setTimeout(() => {
          setErrorMessage("");
        }, 5000);
      }
    } catch (error) {
      setSuccessMessage("");
      setErrorMessage("Erreur lors de l'envoi des données d'étalonnage.");
      console.error("Error sending calibration data:", error);
      // Auto-hide the error message after 5 seconds
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
    }
  };

  return (
    <div>
      <h4 className="border-solid border-4 border-b-0 border-r-0 border-l-0 border-bleuSTB pt-4 pb-4 text-xl 3xl:text-3xl underline">
        <div className="inline-flex font-bold underline mb-4">
          Etalonnage Moteur
        </div>
      </h4>
      <button onClick={toggle} className="bg-bleuSTB hover:bg-orange-500 text-white text-base border-none rounded-xl p-2 w-40 sm:w-60 cursor-pointer 3xl:text-2xl">
        Étalonner mon moteur
      </button>
      <Modal isShowing={isShowing} hide={toggle} title="Étalonnage">
        <p className="text-red-500 mb-4 text-xs">Si la valeur envoyée égale à la valeur mesurée alors ne rien saisir.</p>
        <form className="space-y-4" onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <label className="block text-gray-700 font-bold mb-2">Courant (envoyé):</label>
              <input 
                type="text" 
                name="current-sensor" 
                onChange={handleChange} 
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                pattern="\d*\.?\d+"
                title="Veuillez entrer un nombre valide"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-bold mb-2">Courant (mesuré):</label>
              <input 
                type="text" 
                name="current-measured" 
                onChange={handleChange} 
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                pattern="\d*\.?\d+"
                title="Veuillez entrer un nombre valide"
              />
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <label className="block text-gray-700 font-bold mb-2">Conso (envoyé):</label>
              <input 
                type="text" 
                name="conso-sensor" 
                onChange={handleChange} 
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                pattern="\d*\.?\d+"
                title="Veuillez entrer un nombre valide"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-bold mb-2">Conso (mesuré):</label>
              <input 
                type="text" 
                name="conso-measured" 
                onChange={handleChange} 
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                pattern="\d*\.?\d+"
                title="Veuillez entrer un nombre valide"
              />
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <label className="block text-gray-700 font-bold mb-2">Température (envoyée):</label>
              <input 
                type="text" 
                name="temperature-sensor" 
                onChange={handleChange} 
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                pattern="\d*\.?\d+"
                title="Veuillez entrer un nombre valide"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-bold mb-2">Température (mesurée):</label>
              <input 
                type="text" 
                name="temperature-measured" 
                onChange={handleChange} 
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                pattern="\d*\.?\d+"
                title="Veuillez entrer un nombre valide"
              />
            </div>
          </div>
          <div>
            <h5 className="text-lg font-bold mt-4 mb-2">Capteur A</h5>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <label className="block text-gray-700 font-bold mb-2">RMS X (envoyé):</label>
                <input 
                  type="text" 
                  name="rmsAX-sensor" 
                  onChange={handleChange} 
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  pattern="\d*\.?\d+"
                  title="Veuillez entrer un nombre valide"
                />
              </div>
              <div>
                <label className="block text-gray-700 font-bold mb-2">RMS X (mesuré):</label>
                <input 
                  type="text" 
                  name="rmsAX-measured" 
                  onChange={handleChange} 
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  pattern="\d*\.?\d+"
                  title="Veuillez entrer un nombre valide"
                />
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-2">
              <div>
                <label className="block text-gray-700 font-bold mb-2">RMS Y (envoyé):</label>
                <input 
                  type="text" 
                  name="rmsAY-sensor" 
                  onChange={handleChange} 
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  pattern="\d*\.?\d+"
                  title="Veuillez entrer un nombre valide"
                />
              </div>
              <div>
                <label className="block text-gray-700 font-bold mb-2">RMS Y (mesuré):</label>
                <input 
                  type="text" 
                  name="rmsAY-measured" 
                  onChange={handleChange} 
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  pattern="\d*\.?\d+"
                  title="Veuillez entrer un nombre valide"
                />
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-2">
              <div>
                <label className="block text-gray-700 font-bold mb-2">RMS Z (envoyé):</label>
                <input 
                  type="text" 
                  name="rmsAZ-sensor" 
                  onChange={handleChange} 
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  pattern="\d*\.?\d+"
                  title="Veuillez entrer un nombre valide"
                />
              </div>
              <div>
                <label className="block text-gray-700 font-bold mb-2">RMS Z (mesuré):</label>
                <input 
                  type="text" 
                  name="rmsAZ-measured" 
                  onChange={handleChange} 
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  pattern="\d*\.?\d+"
                  title="Veuillez entrer un nombre valide"
                />
              </div>
            </div>
          </div>
          <div>
            <h5 className="text-lg font-bold mt-4 mb-2">Capteur B</h5>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <label className="block text-gray-700 font-bold mb-2">RMS X (envoyé):</label>
                <input 
                  type="text" 
                  name="rmsBX-sensor" 
                  onChange={handleChange} 
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  pattern="\d*\.?\d+"
                  title="Veuillez entrer un nombre valide"
                />
              </div>
              <div>
                <label className="block text-gray-700 font-bold mb-2">RMS X (mesuré):</label>
                <input 
                  type="text" 
                  name="rmsBX-measured" 
                  onChange={handleChange} 
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  pattern="\d*\.?\d+"
                  title="Veuillez entrer un nombre valide"
                />
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-2">
              <div>
                <label className="block text-gray-700 font-bold mb-2">RMS Y (envoyé):</label>
                <input 
                  type="text" 
                  name="rmsBY-sensor" 
                  onChange={handleChange} 
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  pattern="\d*\.?\d+"
                  title="Veuillez entrer un nombre valide"
                />
              </div>
              <div>
                <label className="block text-gray-700 font-bold mb-2">RMS Y (mesuré):</label>
                <input 
                  type="text" 
                  name="rmsBY-measured" 
                  onChange={handleChange} 
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  pattern="\d*\.?\d+"
                  title="Veuillez entrer un nombre valide"
                />
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-2">
              <div>
                <label className="block text-gray-700 font-bold mb-2">RMS Z (envoyé):</label>
                <input 
                  type="text" 
                  name="rmsBZ-sensor" 
                  onChange={handleChange} 
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  pattern="\d*\.?\d+"
                  title="Veuillez entrer un nombre valide"
                />
              </div>
              <div>
                <label className="block text-gray-700 font-bold mb-2">RMS Z (mesuré):</label>
                <input 
                  type="text" 
                  name="rmsBZ-measured" 
                  onChange={handleChange} 
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  pattern="\d*\.?\d+"
                  title="Veuillez entrer un nombre valide"
                />
              </div>
            </div>
          </div>
          <button 
            type="submit" 
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Valider
          </button>
        </form>
        {successMessage && (
          <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-green-500 border border-green-700 text-white px-4 py-3 rounded transition-opacity duration-500 ease-in-out opacity-100">
            <strong className="font-bold">Succès!</strong>
            <span className="block sm:inline"> {successMessage}</span>
          </div>
        )}
        {errorMessage && (
          <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-red-500 border border-red-700 text-white px-4 py-3 rounded transition-opacity duration-500 ease-in-out opacity-100">
            <strong className="font-bold">Erreur!</strong>
            <span className="block sm:inline"> {errorMessage}</span>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default EtalonnageMoteur;
