import React from "react";
import Header from "../Header/Header";

const Mentions = () => {
  return (
    <div className="min-h-screen flex flex-col text-base">
    <Header />
    <div className="container mx-auto mt-8 px-4 lg:px-8">
      <h2 className="text-4xl font-bold mb-8">Mentions légales</h2>

      <div className="mb-6">
        <p className="mb-4">
          Vous disposez d’un droit d’accès, de modification, de rectification et de suppression des données qui vous concernent (art. 34 de la loi « Informatique et Libertés »). 
        </p>
        <p className="mb-4">
          Pour l’exercer, adressez-vous à ALFenvironnement, 28 Chem. de Muret, 31410 Longages ou envoyez-nous un message par notre mail.
        </p>
      </div>

      <div className="mb-8">
        <h3 className="text-3xl font-bold mb-2">Édition du site :</h3>
        <p className="mb-4">
          Le site web diagmotor.dev.alf-environnement.net est édité par la société ALFenvironnement.
        </p>
      </div>

      <div className="mb-8">
        <h3 className="text-3xl font-bold mb-2">Responsable de publication :</h3>
        <p className="mb-4">
          Monsieur Christophe ARNOULD
        </p>
      </div>

      <div className="mb-8">
        <h3 className="text-3xl font-bold mb-2">Hébergeur :</h3>
        <p className="mb-4">
          Le site diagmotor.dev.alf-environnement.net est hébergé par Google Cloud Platform à Paris.
        </p>
        <p className="mb-4">
          Adresse : Google France, 8 rue de Londres, 75009 Paris 
        </p>
        <p className="mb-2">
          Téléphone : 01 42 68 53 00
        </p>
      </div>

      <div className="mb-8">
        <h3 className="text-3xl font-bold mb-2">Contact :</h3>
        <p className="mb-4">
          Pour toute question ou demande d'information, vous pouvez nous contacter à l'adresse suivante :{" "}
          <a href="mailto:contact@alf-environnement.com" className="text-orangeALF hover:underline">
            contact@alf-environnement.com
          </a>
        </p>
      </div>

      {/* Ajoutez d'autres sections et informations au besoin */}

      <p className="text-gray-600 text-sm mt-8">
        Dernière mise à jour : [14/03/2024]
      </p>
    </div></div>
  );
};

export default Mentions;
