import { useState } from "react";
import { API_URL } from "../constants";
import { useHistory } from "react-router-dom";
import axios from 'axios'; // Import Axios

export default function MotorDetailsFormCom({
  motor,
  setMotor,
  setEditMode,
  token
}) {
  const [newComment, setNewComment] = useState(motor.commentaire);
  const navigate = useHistory();

  const showMessagePopup = (message) => {
    alert(message);
  };

  const handleCommentChange = (event) => {
    setNewComment(event.target.value);
  };

  const handleSaveChangesCom = async () => {
    try {
      const response = await axios.post(`${API_URL}/moteur/commentaire`, {
        id_moteur: motor._id,
        commentaire: newComment,
      }, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      if (response.status === 401) {
        showMessagePopup(
          "ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer."
        );
        localStorage.clear(); 
        navigate.push("/login");
        return;
      }

      const json = response.data;
      console.log(json);
      setMotor(json);
      localStorage.setItem("motor", JSON.stringify(json));
      setNewComment(newComment);
      setEditMode(false);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleTextareaClick = (event) => {
    event.stopPropagation();
  };

  const handleModifyButtonClick = (event) => {
    event.stopPropagation();
  };

  return (
    <div>
      <textarea
        className="text-2xl border-4 border-orangeButton rounded-xl p-4 text-black mt-4 mr-2 ml-2 mb-4 w-full"
        value={newComment}
        onChange={handleCommentChange}
        onClick={handleTextareaClick}
      />
      <button
        className="bg-loginBordure hover:bg-blue-500 text-white text-xl rounded-2xl px-11 py-2 ml-4 mb-6 cursor-pointer"
        onClick={(event) => {
          handleSaveChangesCom();
          handleModifyButtonClick(event);
        }}
      >
        Modifier
      </button>
    </div>
  );
}
