import React from "react";
import { AiOutlineSetting } from "react-icons/ai";
import { Link } from "react-router-dom";
import { IconContext } from "react-icons";

const Footer = () => {
  return (
    <div className="bg-bleuSTB flex flex-col items-center text-white p-4">
      <div className="flex flex-col md:flex-row items-center">
        <Link to="/mentions-legales" className="text-xl mr-4 hover:text-marronSTB">
          Mentions légales
        </Link>
        <span className="text-xl mx-4 hidden md:block">|</span>
        <span className="text-xl mx-4 md:hidden block">---</span>
        <span className="text-xl mr-4">
          Développé par{" "}
          <a
            href="https://alf-environnement.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-orangeALF hover:text-marronSTB"
          >
            ALFenvironnement
          </a>
        </span>
      </div>
    </div>
  );
};

export default Footer;
