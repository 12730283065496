import React, { useState, useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./motorDetails.css";
import SelectionGraphique from "../Graphique/SelectionGraphique";
import DateForm from "./DateForm";
import { API_URL } from "../constants";
import { FaEdit } from "react-icons/fa";
import moment from "moment";
import 'leaflet/dist/leaflet.css';
import useModal from '../Modal/useModal';
import MotorImage from "./MotorImage";
import MotorDetailsFormNom from "./MotorDetailsFormNom";
import MotorDetailsFormCom from "./MotorDetailsFormCom";
import MaintenanceList from './MaintenanceList';
import AddMaintenance from "./AddMaintenance";
import TypoSelect from "./TypoSelect";
import axios from "axios";
import ErrorMessages from "./ErrorMessages";
import InfosMoteur from "./infosMoteur";
import ClientLocalisation from "./ClientLocalisation";
import EmailFrequencySelect from "./EmailFrequencySelect";
import EtalonnageMoteur from "./etalonnageMoteur";
import { useHistory } from "react-router-dom";
import { FaChevronDown } from 'react-icons/fa';

export default function MotorDetails(props) {
  const user = JSON.parse(localStorage.getItem("user")) ?? props.user;
  const token = user.token;
  const isAdmin = user && user.admin;
  const navigate = useHistory();
  const [expanded, setExpanded] = useState(false);
  const [expandedMaintenance, setExpandedMaintenance] = useState(false);
  const [motor, setMotor] = useState(
    localStorage.getItem("motor")
      ? JSON.parse(localStorage.getItem("motor"))
      : props.selectedMotor
  );
  const [editMode, setEditMode] = useState(false);
  const [showErrorDetails, setShowErrorDetails] = useState(false);
  const [maintenanceFreqIntervention, setMaintenanceFreqIntervention] = useState([]);
  const [isMaintenanceFreqModified, setIsMaintenanceFreqModified] = useState(false);
  const [showAddMaintenanceForm, setShowAddMaintenanceForm] = useState(false);
  const [newMaintenanceName, setNewMaintenanceName] = useState("");
  const [newFreqIntervention, setNewFreqIntervention] = useState("");
  const [isSuggestionClicked, setIsSuggestionClicked] = useState(false);
  const [error, setError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [lastTrame, setLastTrame] = useState("");

  const showMessagePopup = (message) => {
    alert(message);
  };

  const clearMessages = () => {
    setTimeout(() => {
      setError("");
    }, 5000);
  };

  useEffect(() => {
    clearMessages();
  }, [error]);

  const { isShowing: isSuppModalShowing, toggle: toggleSuppModal } = useModal();

  const handleSuggestionClick = (suggestion) => {
    setNewMaintenanceName(suggestion.nomMaintenance);
    setNewFreqIntervention(suggestion.freqIntervention);
    setIsSuggestionClicked(true);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setNewMaintenanceName(value);
    setIsSuggestionClicked(false);
    setError("");
  };

  const handleMaintenanceFreqChange = (index, event) => {
    const newValue = parseInt(event.target.value);
    let tabFreqIntervention = [...maintenanceFreqIntervention];
    tabFreqIntervention[index] = newValue;
    setMaintenanceFreqIntervention([...tabFreqIntervention]);
    setIsMaintenanceFreqModified(newValue !== props.defaultMaintenanceFreq);
  };

  const toggleErrorDetails = () => {
    setShowErrorDetails(prevState => !prevState);
  };

  function isLongName(name) {
    const words = name.split(' ');
    return words.some(word => word.length > 10);
  }

  function truncateName(name) {
    const words = name.split(' ');
    const truncatedWords = words.map(word => {
      if (word.length > 10) {
        return word.substring(0, 10) + '...';
      }
      return word;
    });
    return truncatedWords.join(' ');
  }

  useEffect(() => {
    getTrame();
  }, [props.selectedMotor]);

  useEffect(() => {
    if (maintenanceFreqIntervention.length === 0) {
      let tabFreqIntervention = [];
      motor.maintenances.forEach((maintenance) => {
        tabFreqIntervention.push(maintenance.freqIntervention);
      });
      console.log(tabFreqIntervention);
      setMaintenanceFreqIntervention([...tabFreqIntervention]);
    }
  }, [motor]);

  const [positionAorB, setPositionAorB] = useState(true);
  const [donnee, setDonnee] = useState({
    idmoteur: motor._id,
    debut: moment().subtract(1, "week").format("YYYY-MM-DD"),
    fin: moment().format("YYYY-MM-DD"),
    position: "",
    granularity: "day",
  });
  const [data, setData] = useState([]);
  const [recherche, setRecherche] = useState(false);

  function togglePosition() {
    setPositionAorB(!positionAorB);
    return;
  }

  async function getTrame() {
    try {
      const newEndDate = moment(donnee.fin).add(1, 'day').format('YYYY-MM-DD');
      const response = await axios.get(`${API_URL}/?d1=${donnee.debut}&d2=${newEndDate}&id=${donnee.idmoteur}&granularity=${donnee.granularity}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      });

      if (response.status === 401) {
        showMessagePopup('ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer.');
        localStorage.clear();
        navigate.push('/login');
        return;
      }

      const json = response.data;
      setData(json.data);
      setRecherche(true);

      // Vérifiez si la dernière date de trame est supérieure à une heure
      if (json.data.length > 0) {
        const lastTrameDate = moment(json.data[json.data.length - 1].timestamp);
        const now = moment();
        const duration = moment.duration(now.diff(lastTrameDate));
        const latestTrame = json.data[json.data.length - 1];
        setLastTrame(latestTrame);
        if (duration.asHours() >= 1) {
          const days = Math.floor(duration.asDays());
          const hours = duration.hours();
          const minutes = duration.minutes();
          let durationMessage = "Le capteur ne renvoie plus de données depuis ";
          if (days > 0) {
            durationMessage += `${days} jours et `;
          }
          if (hours > 0) {
            durationMessage += `${hours} heures`;
          }
          setErrorMessage(durationMessage);
        } else {
          setErrorMessage("");
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  }

  const ajouterMaintenance = async () => {
    if (!newMaintenanceName || !newFreqIntervention) {
      setError('Veuillez remplir tous les champs avant de valider !');
      return;
    }
    const freqIntervention = parseInt(newFreqIntervention);
    if (freqIntervention < 0) {
      setError("La fréquence d'intervention ne peut pas être négative !");
      return;
    }

    try {
      const response = await axios.post(
        `${API_URL}/typo/addmaintenance`,
        {
          id_moteur: motor._id,
          maintenance: {
            nomMaintenance: newMaintenanceName,
            freqIntervention: parseInt(newFreqIntervention),
            lastIntervention: motor.dureeFct,
          },
        },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      );

      if (response.status === 401) {
        showMessagePopup('ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer.');
        localStorage.clear();
        navigate.push('/login');
        return;
      }

      if (response.status === 200) {
        const json = response.data;
        console.log(json);
        setMotor(json);
        localStorage.setItem('motor', JSON.stringify(json));
        let tabFreqIntervention = [...maintenanceFreqIntervention];
        tabFreqIntervention.push(newFreqIntervention);
        setMaintenanceFreqIntervention(tabFreqIntervention);
        setNewMaintenanceName('');
        setNewFreqIntervention('');
        setShowAddMaintenanceForm(false);
      } else {
        console.error("Erreur lors de l'ajout de la maintenance");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    getTrame();
  };

  useEffect(() => {
    positionAorB
      ? setDonnee({ ...donnee, position: "a" })
      : setDonnee({ ...donnee, position: "b" });
  }, [positionAorB]);

  return (
    <div className="min-h-screen flex flex-col text-base sm:text-3xl">
      <Header user={user} />
      <div className="flex flex-auto h-full w-full justify-center text-gray-700">
        <div className="flex flex-col items-center w-full">
          <ErrorMessages motor={motor} />
          {errorMessage && (
            <div className="bg-red-500 text-white p-5 rounded mb-4 mt-4">
              {errorMessage}
            </div>
          )}
          <div className="flex flex-col lg:flex-row w-11/12 p-4 space-y-4 lg:space-y-0 lg:space-x-4">
            <div className="border-solid border-4 border-bleuSTB rounded-lg p-8 lg:w-1/2">
              <h2
                className={`text-left${expanded ? 'cursor-pointer' : ''}`}
                onClick={() => setExpanded(!expanded)}
              >
                <div className="flex items-center justify-center">
                  {editMode ? (
                    <div>
                      {isAdmin ? (
                        <MotorDetailsFormNom
                          motor={motor}
                          setMotor={setMotor}
                          setEditMode={setEditMode}
                          token={token}
                        />
                      ) : (
                        <h2 className={`text-center text-loginBordure text-2xl mb-4 font-bold ${isLongName(motor.nom_moteur) && window.innerWidth < 1024 ? 'text-overflow' : ''}`}>{motor.nom_moteur}</h2>
                      )}
                    </div>
                  ) : (
                    <div className={`inline-flex items-center text-xl lg:text-5xl 3xl:text-6xl mb-4 font-bold text-marronSTB text-center ${isAdmin ? 'cursor-pointer' : ''}`}>
                      {isLongName(motor.nom_moteur) && window.innerWidth < 1024 ? (
                        <span className="text-overflow">{truncateName(motor.nom_moteur)}</span>
                      ) : (
                        <span>{motor.nom_moteur}</span>
                      )}
                      {isAdmin && (
                        <FaEdit
                          className="cursor-pointer text-xl 3xl:text-4xl ml-4 hover:text-bleuSTB"
                          onClick={(event) => {
                            event.stopPropagation();
                            setEditMode(!editMode);
                          }}
                        />
                      )}
                      {window.innerWidth < 1024 && (
                        <FaChevronDown
                          className={`ml-8 transition-transform duration-300 transform ${expanded ? 'rotate-180' : ''}`}
                        />
                      )}
                    </div>
                  )}
                </div>
                <div className={`${expanded ? 'block' : 'hidden'} ${window.innerWidth >= 1024 ? 'lg:block' : ''}`}>
                  <h4 className="border-solid border-4 border-r-0 border-l-0 border-bleuSTB pt-4 pb-4 text-xl 3xl:text-3xl tex underline">
                    <div className="inline-flex font-bold underline">
                      Commentaires
                      {!isAdmin && (
                        <FaEdit
                          className="cursor-pointer ml-4 hover:text-loginBordure"
                          onClick={(event) => {
                            event.stopPropagation();
                            setEditMode(!editMode);
                          }}
                        />
                      )}
                    </div>
                    {editMode ? (
                      <div>
                        <MotorDetailsFormCom motor={motor} setMotor={setMotor} setEditMode={setEditMode} token={token} />
                      </div>
                    ) : (
                      <div>
                        <span className="inline-block text-decoration-none font-light mb-4 mt-4">{motor.commentaire}</span>
                      </div>
                    )}
                  </h4>
                  {isAdmin && (
                        <h4 className="border-solid border-4 border-t-0 border-r-0 border-l-0 border-bleuSTB pt-4 pb-4 text-xl 3xl:text-3xl underline font-bold">
                          Niveau de champ Wi-Fi reçu
                        <br />
                        <span className="inline-block text-decoration-none font-light mb-4 mt-4">
                          {lastTrame.signal} dbm 
                        </span> 
                      </h4>
                      )}
                  <InfosMoteur motor={motor} />
                  <ClientLocalisation motor={motor} token={token} setMotor={setMotor} />
                  <EmailFrequencySelect motor={motor} token={token} setMotor={setMotor} />
                  <EtalonnageMoteur motor={motor} token={token} setMotor={setMotor} /> 
                </div>
              </h2>
            </div>
            <div className="border-solid border-4 border-bleuSTB rounded-lg p-8 lg:w-1/2">
              <h2
                className={`items-center text-marronSTB font-bold text-xl xl:text-5xl 3xl:text-6xl cursor-pointer mb-4 flex`}
                onClick={() => setExpandedMaintenance(!expandedMaintenance)}
              >
                <div className="ml-auto mr-auto">Maintenance</div>
                {window.innerWidth < 1024 && (
                  <FaChevronDown
                    className={`ml-auto mr-auto transition-transform duration-300 transform ${expandedMaintenance ? 'rotate-180' : ''}`}
                  />
                )}
              </h2>
              
              {motor.maintenances.length > 0 ? (
                <div>
                  <div className={`${expandedMaintenance ? 'block' : 'hidden'} ${window.innerWidth >= 1024 ? 'lg:block' : ''}`}>
                    <h4 className="border-solid border-4 border-r-0 border-l-0 border-bleuSTB pt-4 pb-4 text-xl 3xl:text-3xl underline font-bold">
                      Durée de fonctionnement
                      <br />
                      <span className="inline-block text-decoration-none font-light mb-4 mt-4">{parseInt(motor.dureeFct)} h</span>
                    </h4>
                    <MaintenanceList
                      motor={motor}
                      setMotor={setMotor}
                      token={token}
                      maintenanceFreqIntervention={maintenanceFreqIntervention}
                      handleMaintenanceFreqChange={handleMaintenanceFreqChange}
                      setIsMaintenanceFreqModified={setIsMaintenanceFreqModified}
                      showAddMaintenanceForm={showAddMaintenanceForm}
                      newMaintenanceName={newMaintenanceName}
                      newFreqIntervention={newFreqIntervention}
                      handleInputChange={handleInputChange}
                      ajouterMaintenance={ajouterMaintenance}
                      isSuppModalShowing={isSuppModalShowing}
                      toggleSuppModal={toggleSuppModal}
                      props={props}
                    />
                    <AddMaintenance
                      newMaintenanceName={newMaintenanceName}
                      handleInputChange={handleInputChange}
                      newFreqIntervention={newFreqIntervention}
                      setNewFreqIntervention={setNewFreqIntervention}
                      ajouterMaintenance={ajouterMaintenance}
                      error={error}
                      isSuggestionClicked={isSuggestionClicked}
                      handleSuggestionClick={handleSuggestionClick}
                      token={token}
                    />
                  </div>
                </div>
              ) : (
                <TypoSelect 
                  props={props}
                  motor={motor}
                  setMotor={setMotor}
                  token={token}
                />
              )}
            </div>
          
          </div>
          <div className="flex flex-col w-10/12 sm:w-3/5 xl:w-[60%] xl:flex-row items-center border-4 border-bleuSTB rounded-2xl p-6">
            <DateForm
              donnee={donnee}
              setDonnee={setDonnee}
              handleSubmit={handleSubmit}
            />
            <div>
              <MotorImage
                positionAorB={positionAorB}
                togglePosition={togglePosition}
              />
            </div>
          </div>
          <div className="p-8">
            {recherche ? (
              data.length > 0 ? (
                <SelectionGraphique
                  data={data}
                  motor={motor}
                  type={motor.type}
                  freqAlim={motor.freqAlim}
                  freqRot={motor.freqRot}
                  position={donnee.position}
                  courantRef={motor.courantRef}
                />
              ) : (
                <span className="3xl:text-5xl">
                  Pas de données sur la période désirée
                </span>
              )
            ) : (
              <p>
                Veuillez sélectionner des dates pour obtenir les mesures
                correspondantes
              </p>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
