import { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./motorsList.css";
import { API_URL } from "../constants";
import moment from "moment";
import { FaChevronDown, FaChevronUp, FaTrash } from "react-icons/fa";

export default function MotorsList(props) {
  const navigate = useHistory();
  const [motorsList, setMotorsList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [hoveredRowIndex, setHoveredRowIndex] = useState(-1);
  const [isMobileView, setIsMobileView] = useState(false);
  const [expandedMotors, setExpandedMotors] = useState([]);
  const [errorMessages, setErrorMessages] = useState({});

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [moteurToDelete, setMoteurToDelete] = useState(null);

  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : props.user;

  const { token, userId } = user;
  const isAdmin = user.admin;

  useEffect(() => {
    getMotorsList();
    checkMobileView();
    window.addEventListener("resize", checkMobileView);
    return () => {
      window.removeEventListener("resize", checkMobileView);
    };
  }, [userId]);

  const checkMobileView = () => {
    setIsMobileView(window.innerWidth < 1024);
  };

  const showMessagePopup = (message) => {
    alert(message);
  };

  const getMotorsList = async () => {
    if (userId !== "") {
      try {
        const response = await axios.get(
          `${API_URL}/moteur/listmoteur?id=${userId}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );

        if (response.status === 401) {
          showMessagePopup(
            "ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer."
          );
          localStorage.clear();
          navigate.push("/login");
          return;
        }

        const json = response.data;
        console.log(json);
        setMotorsList(json.data);
        checkMotorsData(json.data);
      } catch (error) {
        console.error(error.message);
      }
    }
  };

  const checkMotorsData = async (motors) => {
    const newErrorMessages = {};
    for (const motor of motors) {
      const newEndDate = moment().add(1, "day").format("YYYY-MM-DD");
      const startDate = moment().subtract(1, "week").format("YYYY-MM-DD");

      try {
        const response = await axios.get(
          `${API_URL}/?d1=${startDate}&d2=${newEndDate}&id=${motor._id}&granularity=day`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );

        const json = response.data;

        if (json.data.length > 0) {
          const lastTrameDate = moment(json.data[json.data.length - 1].timestamp);
          const now = moment();
          const diff = moment.duration(now.diff(lastTrameDate));
          if (diff.asHours() >= 1) {
            const durationString = `${Math.floor(diff.asDays())} jours, ${diff.hours()} heures et ${diff.minutes()} minutes`;
            newErrorMessages[motor._id] = `Le capteur ne renvoie plus de données depuis ${durationString}`;
          }
        }
      } catch (error) {
        console.error(error.message);
      }
    }
    setErrorMessages(newErrorMessages);
  };

  const handleDeleteMachine = async () => {
    try {
      const response = await axios.delete(`${API_URL}/moteur/delete`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        data: {
          id: moteurToDelete,
        },
      });

      if (response.status === 204) {
        console.log("Moteur deleted successfully");
        setShowDeleteModal(false);
        setMoteurToDelete(null);
        window.location.reload();
      } else {
        console.log("Failed to delete moteur. Status:", response.status);
      }
    } catch (error) {
      console.log("Error deleting moteur:", error.message);
    }
  };

  const handleShowDeleteModal = (moteurId) => {
    setMoteurToDelete(moteurId);
    setShowDeleteModal(true);
  };

  const handleRowHover = (index) => {
    setHoveredRowIndex(index);
  };

  const handleClientSelection = (client) => {
    setSearchTerm("");
    setSelectedClient(client);
  };

  const handleLocationSelection = (location) => {
    setSearchTerm("");
    setSelectedLocation(location);
  };

  const filteredMotors = motorsList.filter((motor) => {
    const isMatched =
      motor.nom_moteur.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (selectedClient === "" || motor.client === selectedClient) &&
      (selectedLocation === "" || motor.localisation === selectedLocation);
    return isMatched;
  });

  filteredMotors.sort((a, b) => {
    const getMaintenancePriority = (motor) => {
      const interventionStates = motor.maintenances.map(
        (maintenance) => maintenance.etatIntervention
      );
      if (interventionStates.includes("A faire")) {
        return 1;
      } else if (interventionStates.includes("A prevoir")) {
        return 2;
      } else {
        return 3;
      }
    };

    const vibratoryComparison = b.etatVibratoire.length - a.etatVibratoire.length;
    if (vibratoryComparison !== 0) {
      return vibratoryComparison;
    }

    const maintenanceComparison = getMaintenancePriority(a) - getMaintenancePriority(b);
    return maintenanceComparison;
  });

  const maintenanceMap = motorsList.reduce((map, motor) => {
    const interventionStates = motor.maintenances.map(
      (maintenance) => maintenance.etatIntervention
    );
    let maintenanceState;
    if (interventionStates.includes("A faire")) {
      maintenanceState = "A faire";
    } else if (interventionStates.includes("A prevoir")) {
      maintenanceState = "A prevoir";
    } else {
      maintenanceState = "A jour";
    }
    return { ...map, [motor._id]: maintenanceState };
  }, {});

  const filteredMaintenances = filteredMotors.map(
    (motor) => maintenanceMap[motor._id]
  );

  const uniqueClients = [...new Set(filteredMotors.map((motor) => motor.client))];
  const uniqueLocations = [...new Set(filteredMotors.map((motor) => motor.localisation))];

  const handleRowClick = (index) => {
    if (expandedMotors.includes(index)) {
      setExpandedMotors(expandedMotors.filter((item) => item !== index)); // Si le moteur est déjà étendu, réduisez-le
    } else {
      setExpandedMotors([...expandedMotors, index]);
    }
  };

  return (
    <div className="min-h-screen flex flex-col text-base sm:text-3xl">
      <Header user={user} />
      <div className="flex flex-auto h-full justify-center">
        <div className="p-4 h-fit flex flex-col items-center ">
          <h2 className="text-bleuSTB font-bold text-3xl xl:text-5xl 3xl:text-6xl">Liste des moteurs</h2>
          <div className="flex flex-col lg:flex-row justify-between items-center mb-4 ">
            <div className="flex-1 lg:mr-4">
              <input
                type="text"
                placeholder="Rechercher par nom de moteur..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="mt-4 mb-4 shadow-md w-70 sm:w-96 lg:w-80 xl:w-96 h-5 bg-white text-gray-700 outline-none text-base sm:text-lg xl:text-xl border-4 border-orangeButton hover:border-bleuSTB rounded-3xl p-6"
              />
            </div>
            {isAdmin && (
              <div className="lg:ml-4 shadow-md w-52 sm:w-72 lg:w-80 xl:w-96 h-5 bg-white text-gray-700 outline-none text-base border-4 border-orangeButton hover:border-bleuSTB rounded-3xl p-6 flex items-center">
                <select
                  className="outline-none border-none text-gray-700 bg-white w-full text-base sm:text-lg xl:text-xl 3xl:text-2xl"
                  value={selectedClient}
                  onChange={(e) => handleClientSelection(e.target.value)}
                >
                  <option value="">Tous les clients</option>
                  {uniqueClients.map((client) => (
                    <option key={client} value={client}>
                      {client}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <div className="mt-4 lg:mt-0 lg:ml-8 shadow-md w-70 sm:w-96 lg:w-80 xl:w-96 h-5 bg-white text-gray-700 outline-none text-base border-4 border-orangeButton hover:border-bleuSTB rounded-3xl p-6 flex items-center">
              <select
                className="outline-none border-none text-gray-700 bg-white w-full text-base sm:text-lg xl:text-xl 3xl:text-2xl"
                value={selectedLocation}
                onChange={(e) => handleLocationSelection(e.target.value)}
              >
                <option value="">Toutes les localisations</option>
                {uniqueLocations.map((location) => (
                  <option key={location} value={location}>
                    {location}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {filteredMotors.length === 0 && (
            <p>Aucun moteur correspondant n'a été trouvé.</p>
          )}
          {isMobileView ? (
            <div className="w-full md:w-96">
              {filteredMotors.map((motor, index) => {
                const errorMessage = errorMessages[motor._id];
                return (
                  <div
                    className={`flex flex-col mb-10 ${
                      index === hoveredRowIndex
                    } rounded-lg shadow-md border border-bleuSTB mt-5 ${
                      errorMessage ? "bg-red-200" : ""
                    }`}
                    key={motor._id}
                    onClick={() => {
                      props.setSelectedMotor(motor);
                      navigate.push("/details");
                      if (localStorage.getItem("user")) {
                        localStorage.setItem("motor", JSON.stringify(motor));
                      }
                    }}
                    onMouseEnter={() => handleRowHover(index)}
                    onMouseLeave={() => handleRowHover(-1)}
                    title={errorMessage}
                  >
                    <div className="flex items-center justify-between p-4">
                      <div className="flex items-center">
                        <span className="text-sm md:text-2xl text-bleuSTB font-bold">
                          {motor.nom_moteur}
                        </span>
                        <button
                          type="button"
                          className="ml-2 text-bleuSTB"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRowClick(index);
                          }}
                        >
                          {expandedMotors.includes(index) ? (
                            <FaChevronUp className="ml-4 " />
                          ) : (
                            <FaChevronDown className="ml-4" />
                          )}
                        </button>
                      </div>
                      {isAdmin && (
                        <div className="text-xs md:text-base text-end ml-6">
                          Client: {motor.client}
                        </div>
                      )}
                    </div>
                    {expandedMotors.includes(index) && (
                      <div className="p-4">
                        <div>
                          <span className="font-bold text-xs md:text-xl text-bleuSTB">
                            Date de mise en service:
                          </span>{" "}
                          <span className="text-xs md:text-base ml-1">
                            {moment(motor.dateMiseEnService).format("DD/MM/YYYY")}
                          </span>
                        </div>
                        <div className="mt-2">
                          <span className="font-bold text-xs md:text-xl text-bleuSTB">
                            Localisation:
                          </span>{" "}
                          <span className="text-xs ml-1 md:text-base">
                            {motor.localisation}
                          </span>
                        </div>
                        <div className="mt-2">
                          <span className="font-bold text-xs md:text-xl text-bleuSTB">
                            Consommation:
                          </span>{" "}
                          <span className="text-xs ml-1 md:text-base">
                            {isNaN(motor.consommation)
                              ? ""
                              : motor.nom_moteur === "Turbine 1"
                              ? Math.round(motor.consommation / 3.565)
                              : motor.nom_moteur === "Compresseur"
                              ? Math.round(motor.consommation / 115.5)
                              : Math.round(motor.consommation)}{" "}
                            kWh
                          </span>
                        </div>
                        <div className="mt-2">
                          <span className="font-bold text-xs md:text-xl text-bleuSTB">
                            DureeFct:
                          </span>{" "}
                          <span className="text-xs ml-1 md:text-base">
                            {parseInt(motor.dureeFct)} h
                          </span>
                        </div>
                        <div
                          className={`text-xs md:text-base mt-4 ${
                            filteredMaintenances[index] === "A faire"
                              ? "bg-red-200 text-red-700"
                              : filteredMaintenances[index] === "A prevoir"
                              ? "bg-orange-200 text-orange-700"
                              : "bg-green-200 text-green-700"
                          } rounded-md px-2 py-1`}
                        >
                          <span className="font-semibold">Maintenance:</span>{" "}
                          {filteredMaintenances[index]}
                        </div>
                        <div
                          className={`text-xs mt-2 md:text-base ${
                            motor.etatVibratoire.length > 0
                              ? "bg-red-200 text-red-700"
                              : "bg-green-200 text-green-700"
                          } rounded-md px-2 py-1`}
                        >
                          <span className="font-semibold">Etat Vibratoire:</span>{" "}
                          <span className="text-white">
                            {motor.etatVibratoire.length === 0 ? (
                              <span
                                className="text-green-700"
                                title={`${motor.etatVibratoire.join("\n")}`}
                              >
                                Fonctionnement normal
                              </span>
                            ) : motor.etatVibratoire.length === 1 ? (
                              <span
                                className="text-red-700"
                                title={`${motor.etatVibratoire.join("\n")}`}
                              >
                                1 erreur
                              </span>
                            ) : (
                              <span
                                className="text-red-700"
                                title={`${motor.etatVibratoire.join("\n")}`}
                              >
                                {`${motor.etatVibratoire.length} erreurs`}
                              </span>
                            )}
                          </span>
                        </div>
                        <div className="flex justify-end mt-4">
                          <button
                            className="bg-red-600 hover:bg-red-700 text-white text-base hover:text-white px-4 py-2 rounded-md"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleShowDeleteModal(motor._id);
                            }}
                          >
                            Supprimer
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          ) : (
            <table className="cursor-pointer text-xs md:text-sm lg:text-base xl:text-xl 2xl:text-2xl 3xl:text-3xl">
              <thead className="hidden lg:contents">
                <tr>
                  <th scope="col" className="bg-bleuCielSTB text-white p-2 text-center border-2">
                    Date de mise en service
                  </th>
                  <th scope="col" className="bg-bleuCielSTB text-white p-2 text-center border-2">
                    Moteur
                  </th>
                  {isAdmin && (
                    <th scope="col" className="bg-bleuCielSTB text-white p-2 text-center border-2">
                      Client
                    </th>
                  )}
                  <th scope="col" className="bg-bleuCielSTB text-white p-2 text-center border-2">
                    Localisation
                  </th>
                  <th scope="col" className="bg-bleuCielSTB text-white p-2 text-center border-2">
                    Consommation
                  </th>
                  <th scope="col" className="bg-bleuCielSTB text-white p-2 text-center border-2">
                    DureeFct
                  </th>
                  <th scope="col" className="bg-bleuCielSTB text-white p-2 text-center border-2">
                    Maintenance
                  </th>
                  <th scope="col" className="bg-bleuCielSTB text-white p-2 text-center border-2">
                    Etat vibratoire
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredMotors.map((motor, index) => {
                  const errorMessage = errorMessages[motor._id];
                  return (
                    <tr
                      className={`flex flex-col mb-10 ${index === hoveredRowIndex ? "bg-hoverTable" : ""} ${
                        errorMessage ? "bg-red-200" : ""
                      } lg:table-row`}
                      key={motor._id}
                      onClick={() => {
                        props.setSelectedMotor(motor);
                        navigate.push("/details");
                        if (localStorage.getItem("user")) {
                          localStorage.setItem("motor", JSON.stringify(motor));
                        }
                      }}
                      onMouseEnter={() => handleRowHover(index)}
                      onMouseLeave={() => handleRowHover(-1)}
                      title={errorMessage}
                    >
                      <td className="text-end border-2 border-bleuCielSTB p-4 lg:text-center">
                        <span
                          className="lg:hidden"
                          style={{ float: "left", fontWeight: 500, color: "#f48507", marginRight: "5em" }}
                        >
                          Date de mise en service
                        </span>
                        {moment(motor.dateMiseEnService).format("DD/MM/YYYY")}
                      </td>
                      <td className="text-end border-2 border-bleuCielSTB p-4 lg:text-center text-marronSTB font-bold">
                        <span
                          className="lg:hidden"
                          style={{ float: "left", fontWeight: 500, color: "#f48507", marginRight: "5em" }}
                        >
                          Moteur
                        </span>
                        {motor.nom_moteur}
                      </td>

                      {isAdmin && (
                        <td className="text-end border-2 border-bleuCielSTB p-4 lg:text-center">
                          <span
                            className="lg:hidden"
                            style={{ float: "left", fontWeight: 500, color: "#f48507", marginRight: "5em" }}
                          >
                            Client
                          </span>
                          {motor.client}
                        </td>
                      )}
                      <td className="text-end border-2 border-bleuCielSTB p-4 lg:text-center">
                        <span
                          className="lg:hidden"
                          style={{ float: "left", fontWeight: 500, color: "#f48507", marginRight: "5em" }}
                        >
                          Localisation
                        </span>
                        {motor.localisation}
                      </td>
                      <td className="text-end border-2 border-bleuCielSTB p-4 lg:text-center">
                        <span
                          className="lg:hidden"
                          style={{
                            float: "left",
                            fontWeight: 500,
                            color: "#f48507",
                            marginRight: "5em",
                          }}
                        >
                          Consommation
                        </span>
                        {isNaN(motor.consommation) ? "" : 
                          motor.nom_moteur === "Turbine 1" 
                            ? Math.round(motor.consommation / 3.565) 
                            : motor.nom_moteur === "Compresseur"
                              ? Math.round(motor.consommation / 115.5)
                              : Math.round(motor.consommation)
                        } kWh
                      </td>
                      <td className="text-end border-2 border-bleuCielSTB p-4 lg:text-center">
                        <span
                          className="lg:hidden"
                          style={{ float: "left", fontWeight: 500, color: "#f48507", marginRight: "5em" }}
                        >
                          DureeFct
                        </span>
                        {parseInt(motor.dureeFct)}h
                      </td>
                      <td
                        className={`text-end border-2 border-bleuCielSTB p-4 lg:text-center ${
                          filteredMaintenances[index] === "A faire"
                            ? "bg-red-200"
                            : filteredMaintenances[index] === "A prevoir"
                            ? "bg-orange-200"
                            : "bg-green-200"
                        }`}
                        title=""
                      >
                        <span
                          className="lg:hidden"
                          style={{ float: "left", fontWeight: 500, color: "#f48507", marginRight: "5em" }}
                        >
                          Maintenance
                        </span>
                        <span
                          className={`etatMaintenance ${
                            filteredMaintenances[index] === "A faire"
                              ? "text-red-700"
                              : filteredMaintenances[index] === "A prevoir"
                              ? "text-orange-700"
                              : "text-green-700"
                          }`}
                        >
                          {filteredMaintenances[index]}
                        </span>
                      </td>
                      <td
                        className={`text-end border-2 border-bleuCielSTB p-4 lg:text-center ${
                          motor.etatVibratoire.length > 0 ? "bg-red-200" : "bg-green-200"
                        }`}
                        data-label="État vibratoire"
                        title=""
                      >
                        <span
                          className="lg:hidden"
                          style={{ float: "left", fontWeight: 500, color: "#f48507", marginRight: "5em" }}
                        >
                          État vibratoire
                        </span>
                        <span className="text-white">
                          {motor.etatVibratoire.length === 0 ? (
                            <span className="text-green-700" title={`${motor.etatVibratoire.join("\n")}`}>
                              Fonctionnement normal
                            </span>
                          ) : motor.etatVibratoire.length === 1 ? (
                            <span className="text-red-700" title={`${motor.etatVibratoire.join("\n")}`}>
                              1 erreur
                            </span>
                          ) : (
                            <span className="text-red-700" title={`${motor.etatVibratoire.join("\n")}`}>
                              {`${motor.etatVibratoire.length} erreurs`}
                            </span>
                          )}
                        </span>
                      </td>
                      {isAdmin && (
                        <td className="text-end border-2 border-bleuCielSTB p-4 lg:text-center">
                          <button
                            className="text-bleuSTB hover:text-marronSTB text-3xl cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleShowDeleteModal(motor._id);
                            }}
                            title=""
                          >
                            <FaTrash />
                          </button>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          {showDeleteModal && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white p-4 rounded-lg">
                <p className="text-lg">Êtes-vous sûr(e) de vouloir supprimer ce moteur ?</p>
                <div className="flex justify-end mt-4">
                  <button
                    className="bg-loginBordure hover:bg-gray-300 text-white hover:text-loginBordure hover:font-bold px-4 py-2 rounded-md mr-2"
                    onClick={() => setShowDeleteModal(false)}
                  >
                    Annuler
                  </button>
                  <button
                    className="bg-red-600 hover:bg-red-700 text-white hover:text-white px-4 py-2 rounded-md"
                    onClick={handleDeleteMachine}
                  >
                    Supprimer
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}
