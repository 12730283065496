import React from "react";
import "./admin.css";

export default function MoteurList({ filteredMoteurs, handleMoteurClick }) {
  return (
    <div className="list-none m-0 p-0 text-xl 3xl:text-2xl">
  {filteredMoteurs.map((moteur) => (
    <div key={moteur._id}>
      <li
        className="p-4 mb-2 bg-gray-100 rounded-sm border border-gray-300 transition-all duration-200 ease-in-out hover:bg-gray-200 hover:shadow-md hover:-translate-y-1"
        onClick={() => handleMoteurClick(moteur)}
      >
        {moteur.nom_moteur}
      </li>
    </div>
  ))}
  {filteredMoteurs.length === 0 && (
    <div className="bg-error rounded-lg text-white text-xl mx-auto p-2 text-center mt-8 flex items-center justify-center">
      Le moteur recherché n'existe pas.
    </div>
  )}
</div>
  );
}
