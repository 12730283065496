import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { FaCheckCircle, FaTrash } from 'react-icons/fa';
import Modal from '../Modal/modal';
import axios from 'axios';
import { API_URL } from '../constants';
import { useHistory } from 'react-router-dom';

export default function MaintenanceList({
  motor,
  setMotor,
  token,
  maintenanceFreqIntervention,
  handleMaintenanceFreqChange,
  setIsMaintenanceFreqModified,
  isSuppModalShowing,
  toggleSuppModal,
  props,
}) {
  const user =
    localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : props.user;
  const isAdmin = user && user.admin;
  const navigate = useHistory();

  const showMessagePopup = (message) => {
    alert(message);
  };

  // Créez un état local pour stocker les valeurs delaiAPrevoir de chaque maintenance
  const [etatAPrevoirValues, setEtatAPrevoirValues] = useState([]);

  // Initialisez les états locaux avec les valeurs de delaiAPrevoir de chaque maintenance
  useEffect(() => {
    if (motor.maintenances) {
      const initialValues = motor.maintenances.map((maintenance) => maintenance.delaiAPrevoir || '');
      setEtatAPrevoirValues(initialValues);
    }
  }, [motor]);

  // Fonction pour mettre à jour l'état à prévoir
  const handleUpdateEtatAPrevoir = async (maintenanceId, newEtatAPrevoir, index) => {
    try {
      const response = await axios.put(
        `${API_URL}/typo/changedelaiaprevoir`,
        {
          id_moteur: motor._id,
          id_maintenance: maintenanceId,
          delaiAPrevoir: newEtatAPrevoir,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      if (response.status === 401) {
        showMessagePopup(
          'ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer.'
        );
        localStorage.clear();
        navigate.push('/login');
        return;
      }
      const json = response.data;
      setMotor(json);
      localStorage.setItem('motor', JSON.stringify(json));

      // Mettez à jour l'état local de la valeur delaiAPrevoir pour la maintenance spécifiée
      const updatedEtatAPrevoirValues = [...etatAPrevoirValues];
      updatedEtatAPrevoirValues[index] = newEtatAPrevoir;
      setEtatAPrevoirValues(updatedEtatAPrevoirValues);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleMaintenanceDone = async (maintenanceId) => {
    try {
      const response = await axios.put(
        `${API_URL}/typo/updatelastintervention`,
        {
          id_moteur: motor._id,
          id_maintenance: maintenanceId,
          id_user: user.userId,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      if (response.status === 401) {
        showMessagePopup(
          'ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer.'
        );
        localStorage.clear();
        navigate.push('/login');
        return;
      }
      const json = response.data;
      console.log(json);
      setMotor(json);
      localStorage.setItem('motor', JSON.stringify(json));
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleDeleteMaintenance = async (maintenanceId) => {
    const response = await axios.delete(`${API_URL}/typo/deletemaintenance`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
      data: {
        id_moteur: motor._id,
        id_maintenance: maintenanceId,
      },
    });
    if (response.status === 401) {
      showMessagePopup(
        'ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer.'
      );
      localStorage.clear();
      navigate.push('/login');
      return;
    }
    const json = response.data;
    console.log(json);
    setMotor(json);
    localStorage.setItem('motor', JSON.stringify(json));
    toggleSuppModal();
  };

  const handleSaveMaintenanceFreq = async (idMaintenance, index) => {
    try {
      const response = await axios.put(
        `${API_URL}/typo/changefreq`,
        {
          id_moteur: motor._id,
          id_maintenance: idMaintenance,
          freqIntervention: maintenanceFreqIntervention[index],
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      if (response.status === 401) {
        showMessagePopup(
          'ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer.'
        );
        localStorage.clear();
        navigate.push('/login');
        return;
      }
      const json = response.data;
      setMotor(json);
      localStorage.setItem('motor', JSON.stringify(json));
      setIsMaintenanceFreqModified(false);
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div>
      <ul className="list-none p-0 m-0">
        {motor.maintenances.map((maintenance, index) => (
          <li key={index} id={maintenance._id} className="text-gray-700 text-sm">
            <h4 className="border-solid border-4 border-t-0 border-r-0 border-l-0 border-bleuSTB pt-4 pb-4 text-sm lg:text-xl 3xl:text-3xl">
              <div className="flex">
                <div className="underline font-bold mb-4 mr-4">{maintenance.nomMaintenance}</div>

                <div
                  className={`etatIntervention ${
                    maintenance.etatIntervention === 'A faire'
                      ? 'text-red-600 font-bold text-xs lg:text-xl 3xl:text-3xl'
                      : maintenance.etatIntervention === 'A prevoir'
                      ? 'text-orange-600 font-bold text-xs lg:text-xl 3xl:text-3xl'
                      : 'text-green-600 font-bold text-xs lg:text-xl 3xl:text-3xl'
                  }`}
                >
                  {maintenance.etatIntervention}
                </div>
              </div>

              <div className="flex flex-row items-center flex-start gap-4 mb-4">
                <span className="text-gray-700 text-xs lg:text-sm font-bold 3xl:text-xl">
                  Dernière intervention :
                </span>
                <span className="text-xs lg:text-base 3xl:text-xl">
                  {maintenance.lastIntervention !== undefined
                    ? maintenance.lastIntervention.toFixed(0)
                    : ''}
                  {' h le '}
                  {moment(maintenance.dateLastIntervention).format('DD/MM/YYYY')}
                  {' '}
                  {isAdmin && maintenance.lastUser && 'par '}
                  <span className="font-bold">{isAdmin && maintenance.lastUser}</span>
                </span>
              </div>
              <div className="flex flex-row items-center flex-start gap-4 mb-4">
                <span className="text-gray-700 text-xs lg:text-sm font-bold 3xl:text-xl">
                  {maintenance.beforeIntervention !== undefined && maintenance.beforeIntervention < 0
                    ? 'Intervention dépassée de :'
                    : 'Avant la prochaine intervention :'}
                </span>
                <span className="text-xs lg:text-base 3xl:text-xl">
                  {maintenance.beforeIntervention !== undefined && maintenance.beforeIntervention < 0
                    ? `${Math.abs(maintenance.beforeIntervention).toFixed(0)} h`
                    : maintenance.beforeIntervention !== undefined
                    ? `${maintenance.beforeIntervention.toFixed(0)} h`
                    : ''}
                </span>
              </div>
              {isAdmin && (
                <div className="flex flex-col lg:flex-row flex-start mb-4">
                  <span className="text-gray-700 text-xs mb-5 lg:mb-0 lg:text-sm font-bold 3xl:text-xl mr-8">
                    Seuil de prévenance :
                  </span>
                  <div className="grid grid-cols-2 mb-5 3xl:ml-4 lg:mb-0">
                    <span>
                      <>
                        <input
                          className="text-xs lg:text-base 3xl:text-xl text-center border-2 border-orangeButton hover:border-bleuSTB rounded-xl p-2 w-[65%] sm:w-[50%]"
                          type="number"
                          // Utilisez la valeur etatAPrevoir
                          value={etatAPrevoirValues[index]}
                          onChange={(event) =>
                            handleUpdateEtatAPrevoir(maintenance._id, event.target.value, index)
                          }
                          min="1"
                        />
                        <span className="text-base lg:text-xl ml-2 mr-2">h</span>
                      </>
                    </span>

                    <button
                      className="bg-bleuSTB 3xl:text-xl text-white text-base rounded-xl w-20 sm:w-[47%] cursor-pointer hover:bg-marronSTB sm:-ml-16"
                      onClick={() => {
                        // Utilisez l'ID de la maintenance et la nouvelle valeur etatAPrevoir
                        handleUpdateEtatAPrevoir(maintenance._id, etatAPrevoirValues[index], index);
                      }}
                    >
                      Valider
                    </button>
                  </div>
                </div>
              )}
              <div>
                <div className="flex flex-col lg:flex-row flex-start mb-4">
                  <span className="text-gray-700 text-xs mb-5 lg:mb-0 lg:text-sm font-bold 3xl:text-xl">
                    Fréquence d'intervention :
                  </span>{' '}
                  <div className="grid grid-cols-2 mb-5 3xl:ml-4 lg:mb-0">
                    <span>
                      {isAdmin ? (
                        <>
                          <input
                            className="text-xs lg:text-base 3xl:text-xl text-center border-2 border-orangeButton hover:border-bleuSTB rounded-xl p-2 w-[65%] sm:w-[50%]"
                            type="number"
                            value={maintenanceFreqIntervention[index]}
                            onChange={(event) => handleMaintenanceFreqChange(index, event)}
                            min="0"
                          />
                          <span className="text-base lg:text-xl ml-2 mr-2">h</span>
                        </>
                      ) : (
                        <>
                          <input
                            className="text-xs 3xl:text-xl lg:text-base border-2 border-orangeButton rounded-xl p-2 w-[100%] sm:w-[50%] mr-2 ml-5"
                            type="text"
                            value={maintenanceFreqIntervention[index] + ' h'}
                            readOnly
                          />
                        </>
                      )}
                    </span>
                    {isAdmin && (
                      <button
                        className="bg-bleuSTB 3xl:text-xl text-white text-base rounded-xl w-20 sm:w-[47%] cursor-pointer hover:bg-marronSTB "
                        onClick={() => {
                          handleSaveMaintenanceFreq(maintenance._id, index);
                        }}
                      >
                        Valider
                      </button>
                    )}
                  </div>
                  <div className="grid grid-cols-2 mt-2 lg:mt-0">
                    <button
                      className="flex flex-row items-center bg-green-600 hover:bg-green-700 text-white text-xs 3xl:text-xl border-none rounded-xl px-2 sm:px-3 py-1 no-underline cursor-pointer mr-1 sm:mr-2 w-21 sm:w-auto"
                      onClick={() => {
                        handleMaintenanceDone(maintenance._id);
                      }}
                    >
                      <FaCheckCircle className="text-xl sm:text-4xl mr-2" />
                      <span>Mettre à jour</span>
                    </button>
                    {isAdmin && (
                      <button
                        className="flex flex-row items-center bg-red-600 hover:bg-red-700 text-white text-xs 3xl:text-xl border-none rounded-xl px-2 sm:px-3 py-1 no-underline cursor-pointer ml-auto sm:ml-2 w-21 sm:w-auto"
                        onClick={toggleSuppModal}
                      >
                        <FaTrash className="text-xl mr-2" />
                        <span>Supprimer</span>
                      </button>
                    )}
                  </div>
                  <Modal
                    isShowing={isSuppModalShowing}
                    hide={toggleSuppModal}
                    title="Confirmation de suppression"
                  >
                    <p className="text-center 3xl:text-3xl">
                      Êtes-vous sûr de vouloir supprimer cette maintenance ?
                    </p>
                    <div className="flex justify-center gap-6 mt-6 mb-6">
                      <button
                        className="bg-red-700 hover:bg-red-800 text-white 3xl:text-3xl text-base border-none rounded-xl p-2 w-40 cursor-pointer"
                        onClick={() => handleDeleteMaintenance(maintenance._id)}
                      >
                        Supprimer
                      </button>
                      <button
                        className="bg-orangeButton hover:bg-orange-500 text-white 3xl:text-3xl text-base border-none rounded-xl p-2 w-40 cursor-pointer"
                        onClick={toggleSuppModal}
                      >
                        Annuler
                      </button>
                    </div>
                  </Modal>
                </div>
              </div>
            </h4>
          </li>
        ))}
      </ul>
    </div>
  );
}
