import React, { useState } from "react";
import moment from "moment";
import "./motorDetails.css";

const granularities = {
  minute: "Minute",
  hour: "Heure",
  day: "Jour",
  week: "Semaine",
  month: "Mois",
  year: "Année",
};

export default function DateForm(props) {
  const [granularity, setGranularity] = useState(props.donnee.granularity);

  const handleGranularityChange = (event) => {
    const newGranularity = event.target.value;
    setGranularity(newGranularity);
    props.setDonnee({
      ...props.donnee,
      granularity: newGranularity,
    });
  };

  const isDayOrMore =
    moment(props.donnee.fin).diff(moment(props.donnee.debut), "days") >= 1;
    const isTwoDayOrMore =
    moment(props.donnee.fin).diff(moment(props.donnee.debut), "weeks") >= 1;
  const isWeekOrMore =
    moment(props.donnee.fin).diff(moment(props.donnee.debut), "weeks") >= 1;
  const isMonthOrMore =
    moment(props.donnee.fin).diff(moment(props.donnee.debut), "months") >= 1;
  const isYearOrMore =
    moment(props.donnee.fin).diff(moment(props.donnee.debut), "years") >= 1;
    const isTwoYearsOrMore =
  moment(props.donnee.fin).diff(moment(props.donnee.debut), "years") >= 2;

  const isSameDay = moment(props.donnee.fin).isSame(moment(props.donnee.debut), 'day');

  const availableGranularities = (() => {
    if (isTwoYearsOrMore) {
      return ["week","month","year"];
    }
    if (isYearOrMore) {
      if (moment(props.donnee.fin).diff(moment(props.donnee.debut), 'days') === 365) {
        return ["day", "week", "month"]; // Add "day" if the duration is exactly one year
      } else {
        return ["week", "month"]; // Otherwise, return "week" and "month"
      }
    }
    if (isMonthOrMore) {
      return ["day","week"];
    }
    if (isWeekOrMore) {
      return ["hour","day"];
    }
    if (isTwoDayOrMore) {
      return ["minute","hour","day"];
    }
    if (isDayOrMore) {
      return ["minute", "hour", "day"];
    }
    if (isSameDay) {
      return ["minute"];
    }
    return Object.keys(granularities);
  })();
  

  return (
    <form
      className="text-center flex flex-col w-fit xl:w-1/2 mr-4"
      onSubmit={props.handleSubmit}
    >
      <div className="flex flex-col xl:flex-row justify-between">
        <label className="flex flex-col text-center">
          Début
          <input
            type="date"
            name="debut"
            className="text-xl 3xl:text-3xl border-2 border-bleuCielSTB hover:border-bleuSTB rounded-xl py-2 px-4 text-center mt-4 font-sans m-4"
            value={props.donnee.debut}
            onChange={async (event) => {
              await props.setDonnee({
                ...props.donnee,
                [event.target.name]: moment(event.target.value).format(
                  "YYYY-MM-DD"
                ),
              });
              console.log(props.donnee);
            }}
          />
        </label>
        <label className="flex flex-col text-center">
          Fin
          <input
            type="date"
            name="fin"
            className="text-xl 3xl:text-3xl border-2 border-bleuCielSTB hover:border-bleuSTB rounded-xl py-2 px-4 text-center mt-4 font-sans m-4 "
            value={props.donnee.fin}
            onChange={(event) => {
              props.setDonnee({
                ...props.donnee,
                [event.target.name]: moment(event.target.value).format(
                  "YYYY-MM-DD"
                ),
              });
              console.log(props.donnee);
            }}
          />
        </label> 
      </div>
      <label className="flex flex-col text-center">
        Périodicité
        <select
          name="granularity"
          className="text-xl 3xl:text-3xl border-2 border-bleuCielSTB hover:border-bleuSTB rounded-xl py-2 px-4 text-center mt-4 font-sans m-4 "
          value={props.donnee.granularity}
          onChange={handleGranularityChange}
        >
          {availableGranularities.map((granularity) => (
            <option key={granularity} value={granularity}>
              {granularities[granularity]}
            </option>
          ))}
        </select>
      </label>
      <input
        type="submit"
        value="Rechercher"
        className="bg-bleuSTB text-white text-xl 3xl:text-3xl rounded-3xl px-2 py-4 mt-4 cursor-pointer hover:bg-marronSTB w-[80%] ml-auto mr-auto"
      />
    </form>
  );
} 