import React from "react";
import {
  ScatterChart,
  Scatter,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  BarChart,
  Bar,
  Brush,
  ResponsiveContainer,
  Cell,
} from "recharts";

function Graphique(props) {
  const data = props.valdata;
  const type = props.type;

  const dataWithoutUndefined = props.valdata.map((item) => ({
    ...item,
    ordonne: item.ordonne || 0,
  }));

  const colorCurrent = (valeur) => (valeur>props.valdata[0].ordonne*1.1) ? "red" : "#8884d8"
  

  const color = (valeur) => {
    switch (type) {
      case "type1":
        if (valeur < 1.4) {
          return "green";
        }
        if (valeur < 2.8) {
          return "yellow";
        }
        if (valeur < 4.5) {
          return "orange";
        }
        return "red";

      case "type2":
      case "type3":
        if (valeur < 2.3) {
          return "green";
        }
        if (valeur < 4.5) {
          return "yellow";
        }
        if (valeur < 7.1) {
          return "orange";
        }
        return "red";

      case "type4":
        if (valeur < 3.5) {
          return "green";
        }
        if (valeur < 7.1) {
          return "yellow";
        }
        if (valeur < 11) {
          return "orange";
        }
        return "red";

      default:
        return "indigo";
    }
  };

  let graph;

  if (props.ordonne === "temperature") {
    graph = (
      <ResponsiveContainer height={250}>
        <LineChart margin={{ top: 20, right: 30, bottom: 10, left: 30 }} data={props.valdata}>
          <CartesianGrid strokeDasharray="10 1" />
          <XAxis
            type={props.typeX}
            dataKey={props.keyabs}
            name={props.abscisse}
            unit={props.uniteabscisse}
          />
          <YAxis
            type={props.typeY}
            dataKey={props.keyord}
            name={props.ordonne}
            unit={props.uniteordonne}
          />
          <Tooltip cursor={{ strokeDasharray: "3 3" }} />
          <Line
            name="donnee"
            dataKey={props.keyord}
            stroke="indigo"
            strokeWidth={2}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    );
  } else if (props.ordonne === "courant") {
    graph = (
      <ResponsiveContainer height={250}>
        <LineChart margin={{ top: 20, right: 62, bottom: 10, left: 40 }} data={props.valdata} barSize={10}>
          <CartesianGrid strokeDasharray="10 1" />
          <XAxis
            type={props.typeX}
            dataKey={props.keyabs}
            unit={props.uniteabscisse}
            padding={{ right: 10 }}
          />
          <YAxis unit={props.uniteordonne} />
          <Tooltip />
          <Brush dataKey={props.keyabs} stroke="indigo" />
          <Line
            type="linear"
            name={props.ordonne}
            dataKey={props.keyord}
            stroke={(props.ordonne === "courant" && props.valdata && props.valdata[0]) ? colorCurrent(props.valdata[0].courant) : "#8884d8"}
            strokeWidth={2}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    );
  } else if (props.ordonne === "consommation") {
    graph = (
      <ResponsiveContainer height={250}>
        <LineChart margin={{ top: 20, right: 62, bottom: 10, left: 40 }} data={dataWithoutUndefined}>
          <CartesianGrid strokeDasharray="10 1" />
          <XAxis
            type={props.typeX}
            dataKey={props.keyabs}
            unit={props.uniteabscisse}
            padding={{ right: 10 }}
          />
          <YAxis unit={props.uniteordonne} />
          <Tooltip />
          <Brush dataKey={props.keyabs} stroke="indigo" />
          <Line
            type="linear"
            name={props.ordonne}
            dataKey={props.keyord}
            stroke={(props.ordonne === "consommation" && props.valdata && props.valdata[0]) ? colorCurrent(props.valdata[0].courant) : "#8884d8"}
            strokeWidth={2}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    );
  } else if (props.ordonne === "velocité") {
  graph = (
    <ResponsiveContainer height={250}>
      <LineChart margin={{ top: 20, right: 62, bottom: 10, left: 40 }} data={props.valdata} barSize={10}>
        <CartesianGrid strokeDasharray="10 1" />
        <XAxis
          type={props.typeX}
          dataKey={props.keyabs}
          unit={props.uniteabscisse}
          padding={{ right: 10 }}
        />
        <YAxis unit={props.uniteordonne} />
        <Tooltip />
        <Brush dataKey={props.keyabs} stroke="indigo" />
        <Line
          type="linear"
          name={props.ordonne}
          dataKey={props.keyord}
          stroke={(props.ordonne === "courant" && props.valdata && props.valdata[0]) ? colorCurrent(props.valdata[0].courant) : "#8884d8"}
          strokeWidth={2}
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
   else {
    graph = (
      <ResponsiveContainer height={250}>
        <BarChart margin={{ top: 20, right: 62, bottom: 10, left: 40 }} data={props.valdata} barSize={2}>
          <CartesianGrid strokeDasharray="10 1" />
          <XAxis
            type={props.typeX}
            dataKey={props.keyabs}
            unit={props.uniteabscisse}
            padding={{ right: 10 }}
          />
          <YAxis unit={props.uniteordonne} />
          <Tooltip />
          <Brush dataKey={props.keyabs} stroke="indigo" />
          <Bar name={props.ordonne} dataKey={props.keyord} fill="indigo">
            {data.map((entry, index) => (
              <Cell fill={color(entry.ampli)} key={index} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  }

  return (
    <div className="flex flex-col justify-center items-center w-full lg:w-1/2 m-1 lg:m-auto mb-10">
      <div className="text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl ml-5 font-bold text-center">{props.name}</div>
      {graph}
    </div>
  );
}

export default Graphique;