import { Route, Switch, Redirect } from "react-router-dom";
import React from "react";
import { useState } from "react";

import Login from "./Login/Login.js";
import MotorsList from "./MotorsList/MotorsList";
import MotorDetails from "./MotorDetails/MotorDetails";
import Admin from "./Admin/Admin";
import Mentions from "./Mentions legales/Mentions.js";

export const Routes = () => {
  const [selectedMotor, setSelectedMotor] = useState();
  const [user, setUser] = useState();

  // vérifie si l'utilisateur est connecté
  const isAuthenticated = () => {
    if (user !== undefined) {
      return true;
    } else {
      // Vérifier si un utilisateur est stocké localement dans le navigateur
      const storedUser = localStorage.getItem("user");
      if (storedUser !== null) {
        setUser(JSON.parse(storedUser));
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <Switch>

      <Route path="/" exact>
        {isAuthenticated() ? (
          <Redirect to="/motorsList" />
        ) : (
          <Redirect to="/login" />
        )}
      </Route>

      <Route path="/login" exact>
        <Login setUser={setUser} />
      </Route>

      <Route
        path="/motorsList"
        exact
        render={() => {
          if (!isAuthenticated()) {
            // rediriger vers la page de connexion si l'utilisateur n'est pas connecté
            return <Redirect to="/login" />;
          } else {
            // afficher la liste des moteurs si l'utilisateur est connecté
            return (
              <MotorsList
                user={user}
                setSelectedMotor={setSelectedMotor}
              />
            );
          }
        }}
      />

      <Route
        path="/details"
        exact
        render={() => {
          if (!isAuthenticated()) {
            // rediriger vers la page de connexion si l'utilisateur n'est pas connecté
            return <Redirect to="/login" />;
          } else {
            // afficher le détail du moteur si l'utilisateur est connecté
            return (
              <MotorDetails 
                selectedMotor={selectedMotor} 
                user={user} 
              />
            );
          }
        }}
      />

      <Route path="/admin" exact>
        <Admin user={user} />
      </Route>

      <Route path="/mentions-legales" exact>
        <Mentions />
      </Route>
    </Switch>
  );
};