import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../constants";
import { useHistory } from "react-router-dom";

const EmailFrequencySelect = ({ motor, token, setMotor }) => {
  const [emailFrequency, setEmailFrequency] = useState(
    motor.emailFrequency || motor.defaultEmailFrequency
  );
  const navigate = useHistory();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const showMessagePopup = (message) => {
    alert(message);
  };
  const handleEmailFrequencyChange = (e) => {
    setEmailFrequency(e.target.value);
  };

  const handleEmailFrequencyUpdate = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/moteur/frequency`,
        {
          id_moteur: motor._id,
          emailFrequency: emailFrequency,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (response.status === 401) {
        showMessagePopup(
          "ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer."
        );
        localStorage.clear();
        navigate.push("/login");
        return;
      }

      if (response.status === 200) {
        const json = response.data;
        setMotor(json);
        localStorage.setItem("motor", JSON.stringify(json));
        setShowSuccessMessage(true);

        // Masquer le message de succès après 3 secondes
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 3000);
      } else {
        console.error(
          "Erreur lors de la mise à jour de la fréquence d'envoi d'e-mails"
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (showSuccessMessage) {
      console.log("Fréquence mise à jour avec succès !");
    }
  }, [showSuccessMessage]);

  return (
    <div>
      <h4 className="border-solid border-4 border-b-0  border-r-0 border-l-0 border-bleuSTB pt-4 pb-4 text-xl 3xl:text-3xl underline">
        <div className="inline-flex font-bold underline mb-4 ">
          Fréquence d'envoi d'e-mails
        </div>
      </h4>
      <div className="relative">
        <select
          className="block w-full text-xl 3xl:text-3xl p-2 mb-4 border border-gray-300 dark:border-gray-400 rounded-md appearance-none focus:outline-none focus:ring focus:border-blue-500 dark:focus:border-blue-400"
          value={emailFrequency}
          onChange={handleEmailFrequencyChange}
          onClick={(event) => event.stopPropagation()}
        >
          <option value="daily">Tous les jours</option>
          <option value="weekly">Toutes les semaines</option>
          <option value="biweekly">Toutes les deux semaines</option>
          <option value="monthly">Tous les mois</option>
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 dark:text-gray-400">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </div>
      </div>
      <button
        onClick={(event) => {
          handleEmailFrequencyUpdate();
          event.stopPropagation();
        }}
        className="bg-bleuSTB text-xl 3xl:text-3xl hover:bg-marronSTB dark:bg-bleuSTB dark:hover:bg-blue-500 text-white p-2 rounded-md mt-2 mb-4"
      >
        Enregistrer
      </button>

      {showSuccessMessage && (
        <div className="text-green-600 dark:text-green-400 mt-2">
          Fréquence mise à jour avec succès !
        </div>
      )}
    </div>
  );
};

export default EmailFrequencySelect;
