import React, { useState, useEffect } from "react";
import { IconContext } from "react-icons";
import { AiOutlineSetting, AiOutlineLeft } from "react-icons/ai";
import { useHistory, useLocation } from "react-router-dom";
import UserInfos from "./UserInfos";
import useModal from "../Modal/useModal";
import { API_URL } from "../constants";
import logoALF from "./logoALF.png";
import logoSTB from "./logoSTB.png";
import axios from "axios";

export default function Header(props) {
  const [user, setUser] = useState(props.user);
  const location = useLocation();
  const navigate = useHistory();
  const { isShowing: isParametreFormShowed, toggle: toggleParametreForm } = useModal();

  const showMessagePopup = (message) => {
    alert(message);
  };

  const handleDeconnexion = async () => {
    try {
      const response = await axios.put(
        `${API_URL}/user/logout`,
        {
          userId: user.userId,
        },
        {
          headers: {
            mode: "cors",
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + user.token,
          },
        }
      );

      if (response.status === 401) {
        showMessagePopup(
          "ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer."
        );
        localStorage.clear();
        navigate.push("/login");
        return;
      }

      const json = response.data;
      console.log(json);
    } catch (error) {
      console.error(error.message);
    }
  };

  const logout = () => {
    setUser();
    handleDeconnexion();
    localStorage.clear();
    navigate.push("/login");
  };

  const [isFirstPage, setIsFirstPage] = useState(false);

  useEffect(() => {
    setIsFirstPage(window.location.pathname === "/motorsList");
  }, []);

  return (
    <div className="bg-bleuSTB flex items-center flex-col justify-center sm:flex-row sm:justify-evenly text-white w-full">
      <div className="flex flex-row items-center mb-1 mt-1">
        {user && location.pathname !== "/" && (
          <IconContext.Provider
            value={{
              className: "image",
              size: "2em",
              style: {
                marginTop: "auto",
                marginBottom: "auto",
              },
            }}
          >
            {isFirstPage ? (
              <></>
            ) : (
              <AiOutlineLeft
                className="cursor-pointer hover:text-marronSTB text-2xl 3xl:text-5xl mr-5"
                onClick={navigate.goBack}
              />
            )}
          </IconContext.Provider>
        )}
        <a href="/motorsList">
          <img src={logoALF} alt="Logo" className="h-16 sm:h-20 md:h-28 3xl:h-40" />
        </a>
        <a href="/motorsList">
          <img src={logoSTB} alt="Logo STB" className="h-16 sm:h-20 md:h-28 3xl:h-40 ml-4" />
        </a>
      </div>
      {user ? (
        <div className="flex flex-row items-center mb-5 sm:mb-0">
          <button
            className="bg-bleuCielSTB hover:bg-marronSTB text-white text-2xl rounded-2xl py-4 px-6 mr-4 cursor-pointer 3xl:text-4xl 3xl:px-10 3xl:py-10"
            onClick={logout}
          >
            Déconnexion
          </button>
          <IconContext.Provider
            value={{
              className: "image",
              size: "2em",
              tyle: {
                marginTop: "auto",
                marginBottom: "auto",
              },
            }}
          >
            <AiOutlineSetting
              onClick={() => {
                user.admin ? navigate.push("/admin") : toggleParametreForm();
              }}
              className="cursor-pointer hover:text-marronSTB text-2xl 3xl:text-5xl"
            />
          </IconContext.Provider>
        </div>
      ) : (
        <></>
      )}
      <UserInfos user={user} isShowing={isParametreFormShowed} toggle={toggleParametreForm} />
    </div>
  );
}
