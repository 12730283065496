function Pagination({ currentPage, totalPages, onPageChange, dataPerPage, uniqueDates }) {
  const pageRange = 3; 

  const visiblePages = uniqueDates.slice(
    Math.max(0, currentPage - pageRange - 1),
    Math.min(uniqueDates.length, currentPage + pageRange)
  );

  // Inverser l'ordre de toutes les dates disponibles
  // const reversedUniqueDates = [...uniqueDates].reverse();

  // Sélectionner les pages visibles
  // const visiblePages = reversedUniqueDates
  //   .slice(
  //     Math.max(0, currentPage - pageRange - 1),
  //     Math.min(reversedUniqueDates.length, currentPage + pageRange)
  //   );

  return (
    <div className="pagination flex flex-col lg:flex-row justify-center mt-4">
      {currentPage > 1 && (
        <button
          onClick={() => onPageChange(currentPage - 1)}
          className="px-3 py-2 mx-1 mb-4 font-medium text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none focus:ring focus:border-blue-300"
        >
          &laquo; Précédent
        </button>
      )}

      {visiblePages.map((date, index) => (
        <button
          key={index}
          onClick={() => onPageChange(uniqueDates.indexOf(date) + 1)}
          className={`px-3 py-2 mx-1 font-medium text-gray-700 rounded-md hover:bg-gray-300 focus:outline-none focus:ring focus:border-blue-300 ${
            uniqueDates.indexOf(date) + 1 === currentPage ? 'bg-gray-300' : 'bg-gray-200'
          }`}
        >
          {date}
        </button>
      ))}

      {currentPage < totalPages && (
        <button
          onClick={() => onPageChange(currentPage + 1)}
          className="px-3 py-2 mx-1 mt-4 font-medium text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none focus:ring focus:border-blue-300"
        >
          Suivant &raquo;
        </button>
      )}
    </div>
  );
}

export default Pagination; 
