import moteurA from "../image/select_A.png";
import moteurB from "../image/select-b.png";

export default function MotorImage({ positionAorB, togglePosition }) {
  return (
    <img
      className="max-w-full max-h-full h-auto mt-5 xl:mt-0"
      src={positionAorB ? moteurA : moteurB}
      onClick={togglePosition}
      alt="Moteur"
    />
  );
}
