import Graphique from "./Graphique";
import Pagination from "./Pagination";
import "./SelectionGraphique.css";
import moment from "moment";
import { useEffect, useState } from "react";
import iso from './image/ISO.png';

function SelectionGraphique(props) {
  const [temp, setTemp] = useState([]);
  const [conso, setConso] = useState([]);
  const [courant, setCourant] = useState([]);
  const [vitesseX1RMS, setVitesseX1RMS] = useState([]);
  const [vitesseX2RMS, setVitesseX2RMS] = useState([]);
  const [vitesseY1RMS, setVitesseY1RMS] = useState([]);
  const [vitesseY2RMS, setVitesseY2RMS] = useState([]);
  const [vitesseZ1RMS, setVitesseZ1RMS] = useState([]);
  const [vitesseZ2RMS, setVitesseZ2RMS] = useState([]);
  const vitesseX1RMSFiltered = vitesseX1RMS.filter(dataPoint => dataPoint.ordonne !== undefined && dataPoint.ordonne !== null);
  const vitesseX2RMSFiltered = vitesseX2RMS.filter(dataPoint => dataPoint.ordonne !== undefined && dataPoint.ordonne !== null);
  const vitesseY1RMSFiltered = vitesseY1RMS.filter(dataPoint => dataPoint.ordonne !== undefined && dataPoint.ordonne !== null);
  const vitesseY2RMSFiltered = vitesseY2RMS.filter(dataPoint => dataPoint.ordonne !== undefined && dataPoint.ordonne !== null);
  const vitesseZ1RMSFiltered = vitesseZ1RMS.filter(dataPoint => dataPoint.ordonne !== undefined && dataPoint.ordonne !== null);
  const vitesseZ2RMSFiltered = vitesseZ2RMS.filter(dataPoint => dataPoint.ordonne !== undefined && dataPoint.ordonne !== null);
  const consoFiltered = conso.filter(dataPoint => dataPoint.ordonne !== undefined && dataPoint.ordonne !== null);
  const seuilDifferenceTemperature = 8; // Ajustez ce seuil selon vos besoins 

  // const tempFiltered = temp.filter((dataPoint, index) => {
  //   if (index === 0) {
  //     return true;
  //   }
  //   const differenceTemperature = Math.abs(dataPoint.ordonne - temp[index - 1].ordonne);
  //   return differenceTemperature <= seuilDifferenceTemperature || dataPoint.ordonne === 0;
  // });

  

  const motor = localStorage.getItem('motor') ? JSON.parse(localStorage.getItem('motor')) : props.motor;
  const [type, setType] = useState("");
  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : props.user;
  const isAdmin = user && user.admin;
  const [freqAlim, setFreqAlim] = useState(0);
  const [freqRot, setFreqRot] = useState(0);
  const [x1, setX1] = useState([]);
  const [y1, setY1] = useState([]);
  const [z1, setZ1] = useState([]);
  const [x2, setX2] = useState([]);
  const [y2, setY2] = useState([]);
  const [z2, setZ2] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [dataPerPage] = useState(1440);
  const [currentPage, setCurrentPage] = useState(1);
  const uniqueDates = Array.from(new Set(temp.map((item) => item.abscisse.split(' ')[0])));

  const adjustData = (data, calibrationKey) => {
    return data.map(dataPoint => {
      if (dataPoint.ordonne === 0) {
        return dataPoint;
      }
  
      const calibrationValue = motor[calibrationKey];
      if (calibrationValue !== null && calibrationValue !== undefined) {
        if (calibrationValue < 0) {
          dataPoint.ordonne -= Math.abs(calibrationValue);
        } else {
          dataPoint.ordonne += calibrationValue;
        }

        dataPoint.ordonne = parseFloat(dataPoint.ordonne.toFixed(1));
      }
      return dataPoint;
    });
  };
  

  const tempFiltered = temp.map((dataPoint, index) => {
    // Inversion du calcul initial pour obtenir la tension (volt)
    const tempCelsiusInitial = dataPoint.ordonne;
    const volt = -tempCelsiusInitial / 100;
  
    // Application du nouveau calcul
    const tempCelsiusNew = -volt * 1000;
    const tempCNew = tempCelsiusNew * (3.3 / 1023.0) * 100.0;
    const tempC = tempCNew ;
  
    // Arrondir la température avec un chiffre après la virgule
    const tempCNewRounded = parseFloat(tempC.toFixed(1));
  
    // Mise à jour de la température avec la nouvelle valeur arrondie
    return {
      ...dataPoint,
      ordonne: tempCNewRounded
    };
  });

  adjustData(tempFiltered, "etalonnageTemperature");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const paginate = (data, pageNumber) => {
    const startIndex = (pageNumber - 1) * dataPerPage;
    const endIndex = startIndex + dataPerPage;
    return data.slice(startIndex, endIndex);
  };

  const remplirDonneesManquantes = (donneesOriginales) => {
    const donneesRemplies = [];
    const seuilMinute = 60 * 1000; // 1 minute en millisecondes

    for (let i = 0; i < donneesOriginales.length - 1; i++) {
      donneesRemplies.push(donneesOriginales[i]);

      const timestampCourant = moment(donneesOriginales[i].abscisse, "DD/MM/YY HH:mm").valueOf();
      const timestampSuivant = moment(donneesOriginales[i + 1].abscisse, "DD/MM/YY HH:mm").valueOf();

      const differenceTemps = timestampSuivant - timestampCourant;

      if (differenceTemps > seuilMinute) {
        const minutesEcart = Math.floor(differenceTemps / (60 * 1000));
        for (let j = 1; j < minutesEcart; j++) {
          const nouveauTimestamp = timestampCourant + j * 60 * 1000;
          const timestampFormate = moment(nouveauTimestamp).format("DD/MM/YY HH:mm");
          donneesRemplies.push({ abscisse: timestampFormate, ordonne: 0 });
        }
      }
    }

    donneesRemplies.push(donneesOriginales[donneesOriginales.length - 1]);

    return donneesRemplies;
  };

  useEffect(() => {
    var format = props.data;
    var types = props.type;
    var freqAlimentation = props.freqAlim;
    var freqRotation = props.freqRot;

    var tempTab = [];
    var consoTab = [];
    var courantTab = [];
    var vitesseRMSX1Tab = [];
    var vitesseRMSX2Tab = [];
    var vitesseRMSY1Tab = [];
    var vitesseRMSY2Tab = [];
    var vitesseRMSZ1Tab = [];
    var vitesseRMSZ2Tab = [];
    var x1Tab = [];
    var y1Tab = [];
    var z1Tab = [];
    var x2Tab = [];
    var y2Tab = [];
    var z2Tab = [];

    Object.entries(format).forEach(([key1, value]) => {
      format[key1].timestamp = moment(format[key1].timestamp, ["YY-MM-DD", "DD/MM/YY", moment.ISO_8601], true).format("DD/MM/YY HH:mm");

      tempTab.push({ abscisse: format[key1].timestamp, ordonne: format[key1].temperature });
      consoTab.push({ abscisse: format[key1].timestamp, ordonne: format[key1].consommation });
      courantTab.push({ abscisse: format[key1].timestamp, ordonne: format[key1].courant });
      vitesseRMSX1Tab.push({ abscisse: format[key1].timestamp, ordonne: format[key1].vitesseX1RMS });
      vitesseRMSX2Tab.push({ abscisse: format[key1].timestamp, ordonne: format[key1].vitesseX2RMS });
      vitesseRMSY1Tab.push({ abscisse: format[key1].timestamp, ordonne: format[key1].vitesseY1RMS });
      vitesseRMSY2Tab.push({ abscisse: format[key1].timestamp, ordonne: format[key1].vitesseY2RMS });
      vitesseRMSZ1Tab.push({ abscisse: format[key1].timestamp, ordonne: format[key1].vitesseZ1RMS });
      vitesseRMSZ2Tab.push({ abscisse: format[key1].timestamp, ordonne: format[key1].vitesseZ2RMS });

      const fillTab = (tab, freqValues, ampliValues) => {
        Object.entries(freqValues).forEach(([key, value]) => {
          tab.push({ freq: value, ampli: ampliValues[key] });
        });
        tab.sort((a, b) => a.freq - b.freq);
      };
      fillTab(x1Tab, format[key1].frequenceX1, format[key1].ampliX1);
      fillTab(y1Tab, format[key1].frequenceY1, format[key1].ampliY1);
      fillTab(z1Tab, format[key1].frequenceZ1, format[key1].ampliZ1);
      fillTab(x2Tab, format[key1].frequenceX2, format[key1].ampliX2);
      fillTab(y2Tab, format[key1].frequenceY2, format[key1].ampliY2);
      fillTab(z2Tab, format[key1].frequenceZ2, format[key1].ampliZ2);
    });

    setType(types);
    setFreqAlim(freqAlimentation);
    setFreqRot(freqRotation);
    setTemp(remplirDonneesManquantes(tempTab));
    setConso(remplirDonneesManquantes(adjustData(consoTab, "etalonnageConso")));
    setCourant(remplirDonneesManquantes(adjustData(courantTab, "etalonnageCourant")));
    setVitesseX1RMS(remplirDonneesManquantes(adjustData(vitesseRMSX1Tab, "etalonnageVitesseX1RMSA")));
    setVitesseX2RMS(remplirDonneesManquantes(adjustData(vitesseRMSX2Tab, "etalonnageVitesseX2RMSB")));
    setVitesseY1RMS(remplirDonneesManquantes(adjustData(vitesseRMSY1Tab, "etalonnageVitesseY1RMSA")));
    setVitesseY2RMS(remplirDonneesManquantes(adjustData(vitesseRMSY2Tab, "etalonnageVitesseY2RMSB")));
    setVitesseZ1RMS(remplirDonneesManquantes(adjustData(vitesseRMSZ1Tab, "etalonnageVitesseZ1RMSA")));
    setVitesseZ2RMS(remplirDonneesManquantes(adjustData(vitesseRMSZ2Tab, "etalonnageVitesseZ2RMSB")));
    setX1(x1Tab);
    setY1(y1Tab);
    setZ1(z1Tab);
    setX2(x2Tab);
    setY2(y2Tab);
    setZ2(z2Tab);
  }, [props.data, props.type, props.freqAlim, props.freqRot]);

  let graphVib;
  if (props.position === "b") {
    if (selectedOption === "amplitude" || selectedOption === "") {
      graphVib = (
        <div className="flex flex-wrap m-auto items-center justify-center w-full">
          <Graphique
            typeX="category"
            typeY="number"
            name="Vitesse RMS axe X"
            abscisse="date"
            keyabs="abscisse"
            ordonne="velocité"
            keyord="ordonne"
            uniteordonne="mm/s"
            valdata={paginate(vitesseX2RMSFiltered, currentPage)}
            type={type}
          />
          <Graphique
            typeX="category"
            typeY="number"
            name="Vitesse RMS axe Y"
            abscisse="date"
            keyabs="abscisse"
            ordonne="velocité"
            keyord="ordonne"
            uniteordonne="mm/s"
            valdata={paginate(vitesseY2RMSFiltered, currentPage)}
            type={type}
          />
          <Graphique
            typeX="category"
            typeY="number"
            name="Vitesse RMS axe Z"
            abscisse="date"
            keyabs="abscisse"
            ordonne="velocité"
            keyord="ordonne"
            uniteordonne="mm/s"
            valdata={paginate(vitesseZ2RMSFiltered, currentPage)}
            type={type}
          />
          {isAdmin && (
            <>
              <Graphique
                typeX="number"
                typeY="number"
                name="Amplitude des vibrations axe X"
                abscisse="Frequence"
                keyabs="freq"
                uniteabscisse="Hz"
                ordonne="Amplitude"
                keyord="ampli"
                uniteordonne="mm/s"
                valdata={x2}
                type={type}
              />
              <Graphique
                typeX="number"
                typeY="number"
                name="Amplitude des vibrations axe Y"
                abscisse="Frequence"
                keyabs="freq"
                uniteabscisse="Hz"
                ordonne="Amplitude"
                keyord="ampli"
                uniteordonne="mm/s"
                valdata={y2}
                type={type}
              />
              <Graphique
                typeX="number"
                typeY="number"
                name="Amplitude des vibrations axe Z"
                abscisse="Frequence"
                keyabs="freq"
                uniteabscisse="Hz"
                ordonne="Amplitude"
                keyord="ampli"
                uniteordonne="mm/s"
                valdata={z2}
                type={type}
              />
            </>
          )}
          <img
            src={iso}
            alt="iso"
            className="mr-5 ml-2 mt-2"
          />
        </div>
      );
    }

    {(selectedOption === "amplitude") && (
      <img
        src={iso}
        alt="iso"
        id="iso"
        className="sm:mr-[15%] sm:ml-10 sm:mt-10 w-10/12 sm:w-auto"
      />
    )}
  }
  if (props.position === "a") {
    if (selectedOption === "amplitude" || selectedOption === "") {
      graphVib = (
        <div className="flex flex-wrap m-auto items-center justify-center w-full">
          <Graphique
            typeX="category"
            typeY="number"
            name="Vitesse RMS axe X"
            abscisse="date"
            keyabs="abscisse"
            ordonne="velocité"
            keyord="ordonne"
            uniteordonne="mm/s"
            valdata={paginate(vitesseX1RMSFiltered, currentPage)}
            type={type}
          />
          <Graphique
            typeX="category"
            typeY="number"
            name="Vitesse RMS axe Y"
            abscisse="date"
            keyabs="abscisse"
            ordonne="velocité"
            keyord="ordonne"
            uniteordonne="mm/s"
            valdata={paginate(vitesseY1RMSFiltered, currentPage)}
            type={type}
          />
          <Graphique
            typeX="category"
            typeY="number"
            name="Vitesse RMS axe Z"
            abscisse="date"
            keyabs="abscisse"
            ordonne="velocité"
            keyord="ordonne"
            uniteordonne="mm/s"
            valdata={paginate(vitesseZ1RMSFiltered, currentPage)}
            type={type}
          />
          {isAdmin && (
            <>
              <Graphique
                typeX="number"
                typeY="number"
                name="Amplitude des vibrations axe X"
                abscisse="Frequence"
                keyabs="freq"
                uniteabscisse="Hz"
                ordonne="Amplitude"
                keyord="ampli"
                uniteordonne="mm/s"
                valdata={x1}
                type={type}
              />
              <Graphique
                typeX="number"
                typeY="number"
                name="Amplitude des vibrations axe Y"
                abscisse="Frequence"
                keyabs="freq"
                uniteabscisse="Hz"
                ordonne="Amplitude"
                keyord="ampli"
                uniteordonne="mm/s"
                valdata={y1}
                type={type}
              />
              <Graphique
                typeX="number"
                typeY="number"
                name="Amplitude des vibrations axe Z"
                abscisse="Frequence"
                keyabs="freq"
                uniteabscisse="Hz"
                ordonne="Amplitude"
                keyord="ampli"
                uniteordonne="mm/s"
                valdata={z1}
                type={type}
              />
            </>
          )}
          <img
            src={iso}
            alt="iso"
            id="iso"
            className="sm:mr-[15%] sm:ml-10 sm:mt-10 w-10/12 sm:w-auto"
          />
        </div>
      );
    }

    {(selectedOption === "amplitude") && (
      <img
        src={iso}
        alt="iso"
        id="iso"
        className="sm:mr-[15%] sm:ml-10 sm:mt-10 w-10/12 sm:w-auto"
      />
    )}
  }
  return (
    <div>
      <h2 className="text-center m-auto text-gray-700 text-5xl 3xl:text-7xl font-bold mb-10 ">
        Position {props.position.toUpperCase() ? props.position.toUpperCase() : "A"} sélectionnée <br />
      </h2>

      <Pagination
        currentPage={currentPage}
        totalPages={uniqueDates.length}
        onPageChange={handlePageChange}
        dataPerPage={dataPerPage}
        uniqueDates={uniqueDates}
      />

      <select
        value={selectedOption}
        onChange={handleOptionChange}
        className="lg:hidden w-full mx-auto my-10 bg-white border border-gray-300 rounded-md px-4 py-2 text-xl text-gray-700 shadow-md hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
      >
        <option value="">Sélectionnez une option</option>
        <option value="courant">Courant</option>
        <option value="conso">Conso énergétique</option>
        <option value="temp">Température</option>
        <option value="amplitude">Vibrations</option>
      </select>

      <div className="flex flex-wrap m-auto items-center justify-center w-full text-xl mt-8">
        {(selectedOption === "courant" || selectedOption === "") && (
          <Graphique
            typeX="category"
            typeY="number"
            name="Courant"
            abscisse="date"
            keyabs="abscisse"
            ordonne="courant"
            keyord="ordonne"
            uniteordonne="A"
            valdata={paginate(courant, currentPage)}
            courantRef={props.courantRef}
          />
        )}

        {(selectedOption === "conso" || selectedOption === "") && (
          <Graphique
            typeX="category"
            typeY="number"
            name="Consommation énergétique"
            abscisse="date"
            keyabs="abscisse"
            ordonne="consommation"
            keyord="ordonne"
            uniteordonne="kWh"
            valdata={paginate(consoFiltered, currentPage)}
          />
        )}

        {(selectedOption === "temp" || selectedOption === "") && (
          <Graphique
            typeX="category"
            typeY="number"
            name="Température"
            abscisse="date"
            keyabs="abscisse"
            ordonne="temperature"
            keyord="ordonne"
            uniteordonne="°C"
            valdata={paginate(tempFiltered, currentPage)}
          />
        )}

        {graphVib}
      </div>
    </div>
  );
}

export default SelectionGraphique;
