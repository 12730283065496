import React from "react";

const InfosMoteur = ({ motor }) => {
  return (
    <>
      <h4 className="border-solid border-4 border-t-0 border-r-0 border-l-0 border-bleuSTB pt-4 pb-4 text-xl 3xl:text-3xl underline">
        <span className="inline-block text-decoration-none font-light mb-4 ">
          {motor.tension === "mono" ? "Alimentation monophasé" : "Alimentation triphasé"}
        </span>
      </h4>
      <h4 className="border-solid border-4 border-t-0 border-r-0 border-l-0 border-bleuSTB pt-4 pb-4 text-xl 3xl:text-3xl underline font-bold">
        Fréquence d'alimentation
        <br />
        <span className="inline-block text-decoration-none font-light mb-4 mt-4">{motor.freqAlim} Hz</span>
      </h4>
      <h4 className="border-solid border-4 border-t-0 border-r-0 border-l-0 border-bleuSTB pt-4 pb-4 text-xl 3xl:text-3xl underline font-bold">
        Fréquence de rotation
        <br />
        <span className="inline-block text-decoration-none font-light mb-4 mt-4">
          {parseInt(motor.freqRot * 60)} tr/min
        </span>
      </h4>
      <h4 className="border-solid border-4 border-t-0 border-r-0 border-l-0 border-bleuSTB pt-4 pb-4 text-xl 3xl:text-3xl underline font-bold">
        <span className="inline-block text-decoration-none font-light mb-4 mt-4">{motor.type}</span>
      </h4>
    </>
  );
};

export default InfosMoteur;
