import React, { useState, useEffect } from "react";
import L from 'leaflet';
import axios from 'axios';
import { API_URL } from "../constants";
import { useHistory } from "react-router-dom";

const ClientLocalisation = ({ motor, token, setMotor }) => {
  const [showAddClient, setShowAddClient] = useState(false);
  const [client, setClient] = useState("");
  const [localisation, setLocalisation] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [filteredClientList, setFilteredClientList] = useState([]);
  const [selectedFromMap, setSelectedFromMap] = useState(false);
  const [clientLocList, setClientLocList] = useState([]);
  const navigate = useHistory();

  const showMessagePopup = (message) => {
    alert(message);
  };

  useEffect(() => {
    fetchClientLoc();
  }, []);

  const handleClientChange = (event) => {
    const searchText = event.target.value;
    setClient(searchText);

    const filteredList = clientLocList.filter((item) =>
      item.client.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredClientList(filteredList);
  };

  const handleLocalisationChange = async (e) => {
    const searchText = e.target.value;
    setLocalisation(searchText);

    if (!selectedFromMap && searchText.length >= 4) {
      const encodedText = encodeURIComponent(searchText).replace(/%20/g, '+');

      try {
        const response = await axios.get(
          `https://api-adresse.data.gouv.fr/search/?q=${encodedText}`
        );
        const data = response.data;
        console.log(data);
        setSuggestions(data.features.map(feature => feature.properties));
      } catch (error) {
        console.error(error);
      }
    } else {
      setSelectedFromMap(false);
    }
  };

  const openMap = () => {
    const map = L.map('map').setView([46.603354, 1.888334], 6);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution:
        'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
    }).addTo(map);

    let marker = null;

    map.on('click', async (e) => {
      const { lat, lng } = e.latlng;

      try {
        const response = await axios.get(`https://api-adresse.data.gouv.fr/reverse/?lat=${lat}&lon=${lng}`);
        const data = response.data;

        const address = {
          streetNumber: data.features[0].properties.housenumber || '',
          streetName: data.features[0].properties.street || '',
          postalCode: data.features[0].properties.postcode || '',
          city: data.features[0].properties.city || data.features[0].properties.town || data.features[0].properties.village || '',
        };

        const formattedAddress = `${address.streetNumber} ${address.streetName}, ${address.postalCode} ${address.city}`;

        setLocalisation(formattedAddress);
        setSelectedFromMap(true);

        if (marker) {
          map.removeLayer(marker);
        }

        marker = L.marker([lat, lng])
          .addTo(map)
          .bindPopup(formattedAddress)
          .openPopup();
      } catch (error) {
        console.error(error);
      }
    });
  };

  const handleSuggestionAdresse = (address) => {
    setLocalisation(address.label);
  };

  const handleClientLocSelect = (selectedClient, selectedLocalisation) => {
    setClient(selectedClient);
    setLocalisation(selectedLocalisation);
  };

  const handleClientLocSubmit = async () => {
    try {
      const response = await axios.put(`${API_URL}/moteur/setclientloc`, {
        client: client,
        localisation: localisation,
        id_moteur: motor._id,
      }, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (response.status === 401) {
        showMessagePopup("ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer.");
        localStorage.clear(); 
        navigate.push("/login");
        return;
      }
      const json = response.data;
      console.log(json);
      setMotor(json);
      localStorage.setItem("motor", JSON.stringify(json));
    } catch (error) {
      console.error(error);
    }
  };

  const fetchClientLoc = async () => {
    try {
      const response = await axios.get(`${API_URL}/moteur/listclientloc`, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      });
      if (response.status === 401) {
        showMessagePopup("ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer.");
        localStorage.clear(); 
        navigate.push("/login");
        return;
      }
      const data = response.data;
      setClientLocList(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {!motor.client && !motor.localisation ? (
        !showAddClient ? (
          <button
            onClick={(event) => {setShowAddClient(true); event.stopPropagation()}}
            className="bg-bleuSTB text-white text-xl 3xl:text-3xl mb-4  rounded-2xl px-4 py-4 mt-4 cursor-pointer hover:bg-marronSTB"
          >
            Ajouter un client et une localisation
          </button>
        ) : (
          <div>
            <input
              type="text"
              placeholder="Client"
              value={client}
              onChange={handleClientChange}
              className="text-base 3xl:text-xl border-2 border-orangeButton hover:border-bleuSTB w-50 sm:w-50 rounded-xl p-2 mr-2 mt-5"
              onClick={event => event.stopPropagation()}
            />
            <input
              type="text"
              placeholder="Localisation"
              value={localisation}
              onChange={handleLocalisationChange}
              className="text-base 3xl:text-xl border-2 border-orangeButton hover:border-bleuSTB w-96 sm:w-50  rounded-xl p-2 mr-5 mt-5"
              onClick={event => event.stopPropagation()}
            />
            <button
              onClick={(event) => {openMap(); event.stopPropagation();}}
              className="bg-bleuSTB text-white text-xs 3xl:text-2xl rounded-2xl px-4 py-4 mt-4 cursor-pointer hover:bg-marronSTB mr-5"
            >
              Choisir sur la carte
            </button>
            {localisation ? (
              <ul className="list-none p-0 m-0 mt-4 text-xl 3xl:text-3xl font-semibold">
                {suggestions.map((address, index) => (
                  <li
                    key={index}
                    onClick={() => handleSuggestionAdresse(address)}
                    className="p-2 cursor-pointer bg-gray-200 rounded-md mb-4 text-gray-700 transition-colors duration-300 hover:bg-gray-300"
                  >
                    <span className="mr-5 text-2xl">{'→'}</span>{address.label}
                  </li>
                ))}
              </ul>
            ) : null}
            {client ? (
              <ul className="list-none p-0 m-0 mt-4 text-xl 3xl:text-3xl font-semibold">
                {filteredClientList.map((item, index) => (
                  <li
                    key={index}
                    onClick={() => handleClientLocSelect(item.client, item.localisation)}
                    className="p-2 cursor-pointer bg-gray-200 rounded-md mb-4 text-gray-700 transition-colors duration-300 hover:bg-gray-300"
                  >
                    <span className="mr-5 text-2xl">{'→'}</span>{item.client} - {item.localisation}
                  </li>
                ))}
              </ul>
            ) : null}
            <button
              onClick={(event) => {handleClientLocSubmit(); event.stopPropagation();}}
              className="bg-bleuSTB text-white text-xl 3xl:text-3xl rounded-2xl px-10 py-4 mt-4 cursor-pointer hover:bg-marronSTB"
            >
              Valider
            </button>
            <div
              id="map"
              style={{ marginTop: "2em", marginBottom: "2em", height: "400px", width: "100%" }}
              onClick={event => event.stopPropagation()}
            ></div>
          </div>
        )
      ) : (
        <h4 className="pt-4 text-xl 3xl:text-3xl mb-4 underline font-bold">
          {!motor.client ? (
            <div className="">Client</div>
          ) : (
            <h4>
              <div className="">Client</div>
              <br />
              <span className="inline-block text-decoration-none font-light">{motor.client}</span>
            </h4>
          )}
          <br />
          {!motor.localisation ? (
            <div>Localisation</div>
          ) : (
            <h4 className="font-bold">
              <div>Localisation</div>
              <br />
              <span className="inline-block text-decoration-none font-light text-xl 3xl:text-3xl">{motor.localisation}</span>
            </h4>
          )}
        </h4>
      )}
    </>
  );
};

export default ClientLocalisation;
