import React from "react";
import { FaUserPlus, FaUserMinus, FaTrashAlt, FaEdit } from "react-icons/fa";
import { FaUserShield } from "react-icons/fa";
import "./admin.css";
import useModal from '../Modal/useModal';
import Modal from '../Modal/modal';



export default function UserTable({ users, deleteUser, setSelectedUserId, setSelectedUserEmail, setAdminRole , rafraichir , admin }) {
  const { isShowing: isSuppModalShowing, toggle: toggleSuppModal } = useModal();

  const handleConfirmation = (userId) => {
    deleteUser(userId);
    toggleSuppModal();
    window.location.reload();
  };

  const handleSetAdmin = (userId) => {
    setAdminRole(userId, true);
  };

  const handleRemoveAdmin = (userId) => {
    setAdminRole(userId, false);
  };

  const isAdmin = (userId) => {
    const user = users.find((currentUser) => currentUser._id === userId);
    return user && user.admin;
  };

  const handleAdminToggle = (userId) => {
    if (isAdmin(userId)) {
      handleRemoveAdmin(userId);
    } else {
      handleSetAdmin(userId);
    }
    window.location.reload();
  };
  
  return (
    <div>
      <table className="text-xs sm:text-base xl:text-3xl w-full 3xl:text-5xl">
  <thead>
    <tr>
    <th colspan="4" className="bg-bleuSTB text-white px-4 py-2 rounded-tl-3xl rounded-tr-3xl border-t-0 border-loginBordure text-center">Utilisateurs</th>
    </tr>
  </thead>
  <tbody className="contents h-fit-content overflow-y-scroll text-start sm:overflow-y-hidden">
    {users.map((currentUser) => {
      return (
        <tr key={currentUser._id} className="text-left cursor-default even:bg-gray-200 ">
          <td className={currentUser.admin ? "text-red-500 text-left px-4 py-2" : "px-4 py-2"}>
            {currentUser.email}{currentUser.admin && <FaUserShield className="ml-4 inline-block" />}
          </td>
                <td>
                  <FaEdit
                    onClick={() => {
                      setSelectedUserId(currentUser._id);
                      setSelectedUserEmail(currentUser.email);
                      admin(currentUser.admin);
                    }}
                    title="Modifier les informations de l'utilisateur"
                    className="cursor-pointer ml-2 m-2 text-bleuSTB hover:text-marronSTB"
                  />
                </td>
                <td>
                  {currentUser.admin ? (
                    <FaUserMinus
                    className="cursor-pointer m-2 text-bleuSTB hover:text-marronSTB"
                    onClick={() => handleAdminToggle(currentUser._id)}
                    title="Enlever le rôle admin de l'utilisateur"
                  />
                  ) : (
                    <FaUserPlus
                      className="cursor-pointer m-2 text-bleuSTB hover:text-marronSTB"
                      onClick={() => handleAdminToggle(currentUser._id)}
                      title="Ajouter le rôle admin à l'utilisateur"
                    />
                  )}
                </td>
                <td>
                  <FaTrashAlt onClick={toggleSuppModal} title="Supprimer l'utilisateur" className="cursor-pointer m-2 text-bleuSTB hover:text-marronSTB" />
                  <Modal
                    isShowing={isSuppModalShowing}
                    hide={toggleSuppModal}
                    title="Confirmation de suppression"
                  >
                    <p className="text-center text-sm 3xl:text-3xl">Êtes-vous sûr de vouloir supprimer cet utilisateur ?</p>
                    <div className="flex justify-center gap-8 mt-8">
                      <button className="bg-red-600 hover:bg-red-500 text-white 3xl:text-3xl text-base font-medium border-none rounded-md px-2 py-1 w-40 cursor-pointer" onClick={() => handleConfirmation(currentUser._id)}>
                        Supprimer
                      </button>
                      <button className="bg-orangeButton hover:bg-orange-400 text-white 3xl:text-3xl text-base font-medium border-none rounded-md px-2 py-1 w-40 cursor-pointer" onClick={toggleSuppModal}>
                        Annuler
                      </button>
                    </div>
                  </Modal>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="mt-5">
        <span className="text-red-500 text-xs sm:text-base xl:text-xl italic 3xl:text-3xl ">Utilisateurs administrateurs</span>
      </div>
    </div>
  );
}
