import "./header.css";
import Modal from "../Modal/modal";
import { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../constants";
import { useHistory } from "react-router-dom";

export default function UserInfos(props) {
  const [newMail, setNewMail] = useState("");
  const [updatePassword, setUpdatePassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [messageMail, setMessageMail] = useState("");
  const [messagePassword, setMessagePassword] = useState("");
  const navigate = useHistory();

  useEffect(() => {
    if (messagePassword !== "") {
      const timer = setTimeout(() => {
        setMessagePassword("");
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [messagePassword]);

  const showMessagePopup = (message) => {
    alert(message);
  };

  const HandleUpdatePassword = (event) => {
    setUpdatePassword({ ...updatePassword, [event.target.name]: event.target.value });
    var newPass = document.getElementById("newPassword");
    var confirmPass = document.getElementById("confirmPassword");
    if (confirmPass.value !== "") {
      if (newPass.value !== confirmPass.value) {
        newPass.style.backgroundColor = "mistyrose";
        confirmPass.style.backgroundColor = "mistyrose";
        setMessagePassword("Les deux mot de passe ne correspondent pas");
      } else {
        newPass.style.backgroundColor = "";
        confirmPass.style.backgroundColor = "";
        setMessagePassword("");
      }
    }
  };

  const handleSubmitNewMail = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.patch(
        `${API_URL}/user/update`,
        {
          userId: props.user.userId,
          email: newMail,
          admin: true,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + props.user.token,
          },
        }
      );

      if (response.status === 401) {
        showMessagePopup("ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer.");
        localStorage.clear();
        navigate.push("/login");
        return;
      }

      setMessageMail(response.data.message);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleSubmitNewPassword = async (event) => {
    event.preventDefault();
    if (updatePassword.newPassword === updatePassword.confirmPassword) {
      try {
        const responseUpdatePassword = await axios.patch(
          `${API_URL}/user/update`,
          {
            userId: props.user.userId,
            password: updatePassword.newPassword,
            oldPassword: updatePassword.oldPassword,
            admin: props.user.admin,
          },
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + props.user.token,
            },
          }
        );

        if (responseUpdatePassword.status === 401) {
          showMessagePopup("ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer.");
          localStorage.clear();
          navigate.push("/login");
          return;
        }

        if (responseUpdatePassword.status === 500) {
          setMessagePassword(responseUpdatePassword.data.error);
        } else if (responseUpdatePassword.status === 200) {
          setMessagePassword("Le mot de passe a été modifié avec succès !");
        } else {
          setMessagePassword("");
        }
      } catch (error) {
        console.error(error.message);
      }
    } else {
      setMessagePassword("Les deux mots de passe ne correspondent pas.");
    }
  };

  return (
    <Modal
      isShowing={props.isShowing}
      hide={props.toggle}
      title="Gérer mes identifiants"
    >
      <form className="flex flex-col w-full m-auto mt-4" onSubmit={handleSubmitNewMail}>
        <label className="flex flex-col text-left lg:text-2xl 3xl:text-3xl mb-4">
          Nouveau mail
          <input
            type="mail"
            name="mail"
            placeholder="Nouveau Mail"
            onChange={(event) => setNewMail(event.target.value)}
            value={newMail}
            className="text-xs border-solid border-2 border-bleuCielSTB hover:border-bleuSTB rounded-2xl p-1 xl:p-1 text-center 3xl:text-3xl lg:text-2xl mt-2 xl:text-base"
          />
        </label>
        <span id="message">{messageMail}</span>
        <input
          type="submit"
          value="Changer d'adresse Mail"
          className="submitButton bg-bleuSTB hover:bg-marronSTB lg:text-2xl 3xl:text-3xl text-white text-xs sm:text-sm py-2 px-4 rounded-full xl:w-2/3 mx-auto mt-2 mb-1 cursor-pointer"
        />
      </form>
      <form className="flex flex-col w-full m-auto mt-4" onSubmit={handleSubmitNewPassword}>
        <label className="flex flex-col text-left 3xl:text-3xl lg:text-2xl mb-4">
          Nouveau mot de passe
          <input
            type="password"
            placeholder="Ancien Mot de passe"
            name="oldPassword"
            onChange={HandleUpdatePassword}
            value={updatePassword.oldPassword}
            className="text-xs lg:text-2xl 3xl:text-3xl border-solid border-2 border-bleuCielSTB hover:border-bleuSTB rounded-2xl p-1 xl:p-1 text-center mt-2 xl:text-base"
          />
          <input
            type="password"
            id="newPassword"
            placeholder="Nouveau mot de passe"
            name="newPassword"
            onChange={HandleUpdatePassword}
            value={updatePassword.newPassword}
            className="text-xs lg:text-2xl 3xl:text-3xl border-solid border-2 border-bleuCielSTB hover:border-bleuSTB rounded-2xl p-1 xl:p-1 text-center mt-2 xl:text-base"
          />
          <input
            id="confirmPassword"
            type="password"
            placeholder="Confirmer mot de passe"
            name="confirmPassword"
            onChange={HandleUpdatePassword}
            value={updatePassword.confirmPassword}
            className="text-xs lg:text-2xl 3xl:text-3xl border-solid border-2 border-bleuCielSTB hover:border-bleuSTB rounded-2xl p-1 xl:p-1 text-center mt-2 xl:text-base"
          />
        </label>
        <span id="message" style={{borderColor: messagePassword === "Le mot de passe a été modifié avec succès !" ? "green" : (messagePassword === "Les deux mots de passe ne correspondent pas." ? "red" : "")}}>{messagePassword}</span>
        <input
          type="submit"
          value="Changer de Mot de passe"
          className="submitButton bg-bleuSTB hover:bg-marronSTB lg:text-2xl text-white 3xl:text-3xl text-xs sm:text-sm py-2 px-4 rounded-full xl:w-2/3 mx-auto mt-2 mb-1 cursor-pointer"
        />
      </form>
    </Modal>
  );
}
