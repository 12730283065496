import React from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";

const Modal = ({ isShowing, hide, title, children }) =>
  isShowing
    ? createPortal(
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="relative bg-white rounded-lg max-w-md w-full p-6 mx-2 max-h-screen overflow-y-auto">
              <div className="flex justify-end">
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out text-4xl"
                  onClick={hide}
                >
                  &times;
                </button>
              </div>
              <div className="text-center">
                <h3 className="text-2xl leading-6 font-medium text-gray-900">{title}</h3>
                <div className="mt-4">{children}</div>
              </div>
            </div>
          </div>
        </>,
        document.body
      )
    : null;

Modal.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Modal;
